<div class="custom-container flex flex-col rounded-3xl my-8 shadow-custom-1">

  <div class="grid-title-container" [class.with-turnback]="!newTab">
    <app-go-back-btn *ngIf="!newTab" />
    <h1 class="text-white text-3xl font-bold" [class.ml-10]="newTab">{{ bando?.titolo }}</h1>
    <div
      (click)="getPdfSingoloBando()"
      class="flex items-center bg-innexta-cyan hover:bg-transparent transition-colors h-full p-4 rounded-tr-3xl cursor-pointer">
      <div><img src="../../../../../assets/svg/pdf-download.svg" class="h-20 w-20" /></div>
    </div>
  </div>

  <div class="rounded-b-3xl bg-white p-8">
    <div class="flex flex-row gap-5 flex-wrap filtri-bandi">
      <div class="voce" *ngFor="let el of parte_1_voci">
        <div class="title-attribute">
          <mat-icon *ngIf="el.icon" [fontIcon]="el.icon">{{ el.icon }}</mat-icon>
          {{ el.label }}
        </div>
        <ul class="lista-selezionati">
          <li>
            {{ getBandoVoceById(el) }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="custom-container rounded-3xl shadow-custom-1 mt-16 mb-10">
  <div class="bando_description text-justify mx-4 p-5" [innerHTML]="bando?.descrizione"></div>
</div>
