import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, catchError, finalize, throwError } from 'rxjs';
import { UserSessionService } from '../services/user-session/user-session.service';
import { GlobalSpinnerLoaderService } from '../services/spinner-loader/global-spinner-loader.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {
  constructor(
    private userSessionService: UserSessionService,
    private globalSpinnerLoader: GlobalSpinnerLoaderService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}
  private totalRequests = 0;

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.totalRequests++;

    this.globalSpinnerLoader.enableSpinner();

    const finalizeCallback = () => {
      this.totalRequests--;
      if (this.totalRequests < 0) this.totalRequests = 0;
      if (this.totalRequests === 0) this.globalSpinnerLoader.disableSpinner();
    };

    if (req.headers.has(InterceptorSkipHeader)) {
      return next
        .handle(req.clone({ headers: req.headers.delete(InterceptorSkipHeader) }))
        .pipe(finalize(finalizeCallback));
    }

    const token = this.userSessionService.getAccessToken();

    if (token) {
      req = req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
    }
    return next.handle(req).pipe(
      catchError((error: any) => {
        if (error.status === 403) {
          
          this.snackBar.open('Non autorizzato', 'Chiudi', {
            duration: 3000,
            panelClass: ['white-custom'],
          });
          this.router.navigate(['/']);
        }
        if (error.status === 401) {
          this.userSessionService.logout();
          this.snackBar.open('Sessione scaduta', 'Chiudi', {
            duration: 3000,
            panelClass: ['white-custom'],
          });
        }
        return throwError(() => error);
      }),
      finalize(finalizeCallback)
    );
  }
}
