import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { UserSessionService } from '../services/user-session/user-session.service';
import { inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';


export const roleGuardGuard: CanActivateFn = (route:ActivatedRouteSnapshot) => {
  const userSessionService = inject(UserSessionService);
  const router = inject(Router);
  const snackBar = inject(MatSnackBar);


  const allowedRoles = route.data['allowedRoles'] as string[]; // Ottieni i ruoli consentiti dalla routes
  const userRole = userSessionService.role; //Ruolo utente

  // Controlla se il ruolo dell'utente è tra quelli consentiti
  if (allowedRoles.includes(userRole)) {
    return true; // Permetti l'accesso
  }

  // Se l'utente non ha i permessi, reindirizzalo alla home e mostra un messaggio di errore
  router.navigate(['/']);
  snackBar.open('Autorizzazioni insufficienti', 'Chiudi', {
    duration: 3000,
    panelClass: ['white-custom'],
  });
  return false;
};
