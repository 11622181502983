import { CanActivateFn, Router } from '@angular/router';
import { UserSessionService } from '../services/user-session/user-session.service';
import { inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export const onlyAdminGuardGuard: CanActivateFn = () => {
  const userSessionService = inject(UserSessionService);
  const router = inject(Router);
  const snackBar = inject(MatSnackBar);
  if(userSessionService.isAdmin()) {
    return true;
  }
  router.navigate(['/']);
  snackBar.open('Autorizzazioni insufficienti', 'Chiudi', {
    duration: 3000,
    panelClass: ['white-custom'],
  });
  return false;
};
