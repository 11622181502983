import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_ENDPOINT } from 'src/app/app.settings';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StatisticheService {
  constructor(private http: HttpClient) {}

  /*public getPdfBando(bando_id:string): Observable<any> {
    return this.http.post(`${API_ENDPOINT}/api/file/pdf/anteprima?id_bando=${bando_id}`,{});
  }*/

  public getRichiesteTipoSoggetto(idsCCIAA: string): Observable<any> {
    return this.http.post(`${API_ENDPOINT}/statistiche/richieste_tipoSoggetto`, {"camere": idsCCIAA});
  }
  public getRichiesteFinalita(idsCCIAA: string): Observable<any> {
    return this.http.post(`${API_ENDPOINT}/statistiche/richieste_finalita`, {"camere": idsCCIAA});
  }
  public getRichiesteSettore(idsCCIAA: string): Observable<any> {
    return this.http.post(`${API_ENDPOINT}/statistiche/richieste_settore`, {"camere": idsCCIAA});
  }
  public getRichiesteDimensioneInvestimento(idsCCIAA: string): Observable<any> {
    return this.http.post(`${API_ENDPOINT}/statistiche/richieste_dimensioneInvestimento`, {"camere": idsCCIAA});
  }
  public getRichiesteFatturato(idsCCIAA: string): Observable<any> {
    return this.http.post(`${API_ENDPOINT}/statistiche/richieste_fatturato`, {"camere": idsCCIAA});
  }
  public getRichiesteStatoLavorazione(idsCCIAA: string): Observable<any> {
    return this.http.post(`${API_ENDPOINT}/statistiche/richieste_statoLavorazione`, {"camere": idsCCIAA});
  }
  public getRichiesteCamera(): Observable<any> {
    return this.http.post(`${API_ENDPOINT}/statistiche/richieste_camera`, {"camere": ""});
  }
  public getRichiesteMeseAnno(): Observable<any> {
    return this.http.post(`${API_ENDPOINT}/statistiche/richieste_meseAnno`, {"camere": ""});
  }

}
