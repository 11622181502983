<footer class="flex flex-col border-t-4 border-innexta-cyan border-solid">
  <div class="flex flex-col sm:flex-row">
    <div class="flex-1 bg-white flex items-center flex-col pb-5">
      <div>
        <img
        class="mb-2 h-14 max-h-14 mt-2"
        src="../../../assets/svg/logo-union-camere-testo-nero.svg"
        alt="Unioncamere" />

        <p class="text-black">Piazza Sallustio 21 - 00187 Roma</p>
        <p class="text-black"><strong>Tel.:</strong> 06 47041 - Fax: 06 4704240</p>
        <!--&#64; html entity @-->
        <p class="text-black"><strong>PEC:</strong> unioncamere&#64;cert.legalmail.it</p>
        <p class="text-black"><strong>C.F.:</strong> 01484460587</p>
        <p class="text-black"><strong>P.IVA:</strong> 01000211001</p>
      </div>
    </div>
    <div class="flex-1 bg-innexta-footer-black flex flex-col items-center footer-innexta-clip">
      <img
        class="h-full max-h-14 mb-2 mt-2"
        src="../../../assets/img/loghi/innexta-logo-hor-negative-RGB.png"
        alt="INNEXTA" />
      <div class="text-right">
        <p class="text-white font-semibold text-lg">SUPPORTO TECNICO INNEXTA</p>
        <p class="text-white">Via Meravigli 9/b - 20123 Milano</p>
        <p class="text-white"><strong class="text-white">Tel:</strong> 02 8515 4248</p>
        <p class="text-white"><strong class="text-white">E-mail:</strong> info&#64;innexta.it</p>
        <p class="text-white"><strong class="text-white">PEC:</strong> innextascrl&#64;pec.it</p>
        <p class="text-white"><strong class="text-white">P.IVA:</strong> 08502090155</p>
        <br />
      </div>
    </div>
  </div>



  <div class="bg-[#e1e1e1] sm:hidden">
    <nav class="w-full mx-auto container px-4">
      <ul class="flex flex-col py-3 items-center">
        <li class="text-black font-semibold"><a href="../../../assets/pdf/06_Condizioni-generali-del-servizio.pdf" target="_blank" rel="noopener noreferrer">Condizioni generali del servizio</a></li>
        <li class="text-black font-semibold"><a href="../../../assets/pdf/07_Privacy-Policy.pdf" target="_blank" rel="noopener noreferrer">Privacy policy</a></li>
      </ul>
    </nav>
  </div>

  <div class="bg-[#e1e1e1]">
    <nav class="w-full mx-auto container px-4">
      <ul class="flex flex-col sm:flex-row py-3 items-center">
        <div class="flex-1 flex flex-col sm:flex-row items-center"> 
          <p class="text-black">Powered by</p>
          <img
          class="h-full max-h-12"
          src="../../../assets/img/loghi/innexta-logo-hor-positive-RGB.png"
          alt="INNEXTA" />
        </div>
        <div class="flex-1 flex flex-row justify-evenly">
          <div class="hidden sm:inline-flex gap-1 mt-1">
            <a href="../../../assets/pdf/06_Condizioni-generali-del-servizio.pdf" target="_blank" rel="noopener noreferrer" class="text-black">Condizioni generali del servizio</a> |
            <a href="../../../assets/pdf/07_Privacy-Policy.pdf" target="_blank" rel="noopener noreferrer" class="text-black">Privacy policy</a>
          </div>  
          <p class="text-black sm:ml-auto">Version {{ versionBackend }}</p>
        </div>
      </ul>
    </nav>
  </div>
</footer>
