import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TipologicheEditCamereDiCommercioComponent } from './tipologiche-edit-camere-di-commercio/tipologiche-edit-camere-di-commercio.component';
import { TipologicheListAllComponent } from './tipologiche-list-all/tipologiche-list-all.component';
import { TipologicheListSpecificComponent } from './tipologiche-list-specific/tipologiche-list-specific.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';




@NgModule({
  declarations: [
    TipologicheEditCamereDiCommercioComponent,
    TipologicheListAllComponent,
    TipologicheListSpecificComponent,    
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ],
  exports: [
    TipologicheEditCamereDiCommercioComponent,
    TipologicheListAllComponent,
    TipologicheListSpecificComponent,    
  ]
})
export class TipologicheModule { }
