<div class="w-screen h-screen flex flex-col justify-center items-center overflow-hidden gap-5">
  <img src="../../../assets/svg/logo-union-camere-testo-nero.svg" class="max-w-2xl mx-10" />
  <div class="w-full max-w-[950px]">
    <div
      class="flex flex-col m-4 md:m-0 md:flex-row rounded-[20px] overflow-hidden shadow-custom-1">
      <div
        class="bg-innexta-gradient flex-1 flex justify-center flex-col items-center md:rounded-l-[20px] md:clip-path-custom-user-login">
        <h3 class="text-white text-[30px] font-bold mt-14 mb-8">Portale Agevolazioni</h3>
        <img src="../../../assets/svg/immagine-persona-login.svg" class="h-[250px]" />
      </div>
      <div class="flex-1 rounded-r-[20px] bg-white">
        <form
          class="mx-20 flex flex-col h-5/6"
          [formGroup]="signinForm"
          (ngSubmit)="onSubmitSignIn()">
          <h3 class="text-innexta-cyan text-[30px] font-bold mt-5 md:mt-14 text-center">Accedi</h3>
          <label for="email" class="label-style">Email</label>
          <input type="text" formControlName="email" id="email" class="input-style" />

          <label for="password" class="label-style mt-5">Password</label>
          <div class="input-password-style">
            <input
              [type]="showPassword ? 'text' : 'password'"
              formControlName="password"
              id="password" />

            <mat-icon class="showPasswordBtn" (click)="showPassword = !showPassword">{{
              showPassword ? 'visibility_off' : 'visibility'
            }}</mat-icon>
          </div>
          <div
            class="flex flex-col sm:flex-row justify-between items-center gap-5 sm:items-end my-8">
            <div>
              <button class="submit-btn" (click)="onSubmitSignIn()">
                LOGIN 
              </button>
            </div>
            <p class="leading-[1] text-black text-md">
              Hai dimenticato la password
              <a routerLink="/recupera-account" class="text-black font-bold leading-3 hover:text-innexta-cyan"
                >clicca qui</a
              >
            </p>
          </div>
        </form>

        <p class="text-sm text-rosso-scaduto mx-20 mt-5" *ngIf="error">{{ error }}</p>
      </div>
    </div>
  </div>
</div>
