import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { MatIconRegistry } from '@angular/material/icon';
import { HomeComponent } from './routes/home/home.component';
import { SigninComponent } from './routes/signin/signin.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { JWTInterceptor } from './core/interceptors/jwt.interceptor';
import { ContainerLessLayoutComponent } from './layout/container-less-layout/container-less-layout.component';
import { PageNotFoundComponent } from './routes/page-not-found/page-not-found.component';
import { SpinnerLoaderComponent } from './core/services/spinner-loader/spinner-loader.component';
import { SharedModule } from './modules/shared/shared.module';
import { MyCustomPaginatorIntl } from './core/providers/custom-paginator-intl.provider';
import { BandiModule } from './modules/bandi/bandi.module';
import { TipologicheModule } from './modules/tipologiche/tipologiche.module';
import { UserManagementModule } from './modules/user-management/user-management.module';
import { Router, RouterModule, Routes } from '@angular/router';
import { APP_TITLE } from './app.settings';
import { authenticatedGuardGuard } from './core/guard/authenticated-guard.guard';
import { BandiDetailsComponent } from './modules/bandi/bandi-details/bandi-details.component';
import { BandiComponent } from './modules/bandi/bandi-list/bandi.component';
import { RichiediAgevolazioniComponent } from './modules/gestione-richieste/richiedi-agevolazioni/richiedi-agevolazioni.component';
import { TipologicheEditCamereDiCommercioComponent } from './modules/tipologiche/tipologiche-edit-camere-di-commercio/tipologiche-edit-camere-di-commercio.component';
import { TipologicheListAllComponent } from './modules/tipologiche/tipologiche-list-all/tipologiche-list-all.component';
import { TipologicheListSpecificComponent } from './modules/tipologiche/tipologiche-list-specific/tipologiche-list-specific.component';
import { UserDetailsComponent } from './modules/user-management/user-details/user-details.component';
import { UserListComponent } from './modules/user-management/user-list/user-list.component';
import { FormRecoveryPasswordComponent } from './modules/user-management/form-recovery-password/form-recovery-password.component';
import { FormEnterNewPasswordComponent } from './modules/user-management/form-enter-new-password/form-enter-new-password.component';
import { AppInitializerService } from './core/services/app-initalizer/app-initializer.service';
import { Observable } from 'rxjs';
import { onlyAdminGuardGuard } from './core/guard/only-admin-guard.guard';
import { roleGuardGuard } from './core/guard/role-guard.guard';
import { RUOLO_ADMIN, RUOLO_CONSULENTE, RUOLO_OPERATORE } from './core/costanti_ruoli';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {  HttpClient } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import {StatisticheComponent} from "./modules/statistiche/statistiche.component";
import {StatisticheModule} from "./modules/statistiche/statistiche.module";
registerLocaleData(localeIt);

const adminRoutes: Routes = [
  { path: 'users', component: UserListComponent, title: `Lista utenti - ${APP_TITLE}` },
  { path: 'users/new', component: UserDetailsComponent, title: `Nuovo utente - ${APP_TITLE}` },
  { path: 'users/:uuid', component: UserDetailsComponent, title: `Modifica utente - ${APP_TITLE}` },
  //{ path: 'bandi-logging', loadChildren: () => import('./modules/bandi-logging/bandi-logging.module').then(m => m.BandiLoggingModule) },
  {
    path: 'typological',
    component: TipologicheListAllComponent,
    title: `Tipologiche - ${APP_TITLE}`,
  },
  {
    path: 'typological/:name',
    component: TipologicheListSpecificComponent,
    title: `Tipologica - ${APP_TITLE}`,
  },
  {
    path: 'typological/CameraDiCommercio/:id',
    component: TipologicheEditCamereDiCommercioComponent,
    title: `Modifica Camera di commercio - ${APP_TITLE}`,
  },
];

const bandiRoutes: Routes = [
  {
    path: 'bandi',
    component: BandiComponent,
    title: `Lista bandi - ${APP_TITLE}`,
    canActivate: [authenticatedGuardGuard, roleGuardGuard],
    data: {allowedRoles: [RUOLO_ADMIN, RUOLO_CONSULENTE,RUOLO_OPERATORE]}
  },
  {
    path: 'bandi/:id',
    component: BandiDetailsComponent,
    title: `Dettagli bando - ${APP_TITLE}`,
    canActivate: [authenticatedGuardGuard, roleGuardGuard],
    data: {allowedRoles: [RUOLO_ADMIN, RUOLO_CONSULENTE,RUOLO_OPERATORE]}
  },
  {
    path: 'bando/:id',
    component: BandiDetailsComponent,
    title: `Dettagli bando - ${APP_TITLE}`,
    canActivate: [authenticatedGuardGuard, roleGuardGuard],
    data: {newTab: true,allowedRoles: [RUOLO_ADMIN, RUOLO_CONSULENTE,RUOLO_OPERATORE]}
  },
];

export const routes: Routes = [
  { path: 'signin', component: SigninComponent, title: `Login - ${APP_TITLE}` },
  {
    path: 'recupera-account',
    component: FormRecoveryPasswordComponent,
    title: `Recupera password - ${APP_TITLE}`,
  },
  {
    path: 'recupera-account/step-password/:token',
    component: FormEnterNewPasswordComponent,
    title: `Recupera password - ${APP_TITLE}`,
  },

  {
    path: 'embedded/richiedi-agevolazioni',
    component: RichiediAgevolazioniComponent,
    title: `Richiedi agevolazioni - ${APP_TITLE}`,
  },
  {
    path: '',
    component: ContainerLessLayoutComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        component: HomeComponent,
        title: `Home - ${APP_TITLE}`,
        canActivate: [authenticatedGuardGuard],
      },
      {
        path: 'richiedi-agevolazioni',
        component: RichiediAgevolazioniComponent,
        title: `Richiedi agevolazioni - ${APP_TITLE}`,
      },
      ...bandiRoutes,
      {
        path: 'gestione-richieste',
        loadChildren: () =>
          import('./modules/gestione-richieste/gestione-richieste.module').then(
            m => m.GestioneRichiesteModule
          ),
        canActivate: [authenticatedGuardGuard,roleGuardGuard],
        data: {allowedRoles: [RUOLO_ADMIN,RUOLO_OPERATORE]}
      },
      {
        path: 'statistiche',
        component: StatisticheComponent,
        title: `Statistiche - ${APP_TITLE}`,
      }
    ],
  },
  {
    path: 'admin',
    component: ContainerLessLayoutComponent,
    children: adminRoutes,
    canActivate: [authenticatedGuardGuard, onlyAdminGuardGuard],
  },
];

export function initializeApp(appInitializer: AppInitializerService): () => Observable<any> {
  return () => appInitializer.initializeApp();
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({ declarations: [
        AppComponent,
        FooterComponent,
        HeaderComponent,
        HomeComponent,
        SigninComponent,
        ContainerLessLayoutComponent,
        PageNotFoundComponent,
        SpinnerLoaderComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        RouterModule.forRoot(routes),
        BrowserAnimationsModule,
        SharedModule,
        BandiModule,
        StatisticheModule,
        TipologicheModule,
        UserManagementModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })], providers: [
        { provide: LOCALE_ID, useValue: 'it-IT'},
        { provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppInitializerService, Router],
            multi: true,
        },
        { provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
  constructor(iconRegistry: MatIconRegistry) {
    iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
  }
}
