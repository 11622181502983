<div class="custom-container flex flex-col rounded-3xl my-8 shadow-custom-1">
  <div class="rounded-3xl bg-innexta-gradient flex justify-between items-center gap-5 p-4">
    <app-go-back-btn [guard_function]="isNotEdited.bind(this)" />
    <h1 class="text-white text-3xl font-bold text-center w-full">Modifica {{cameraDiCommercio?.nome ?? '-'}}</h1>
  </div>
</div>

<div class="py-8 box-shadow-union">
  <div class="custom-container flex flex-col gap-5">
    <div class="flex flex-col mat-elevation-z8 mb-10 bg-white p-5">
      <h3 class="text-lg font-bold">Immagine CCIAA Corrente</h3>

      <img class="form-input-union-style sm:max-w-[500px] h-auto sm:max-h-[500px] m-auto"
        *ngIf="srcSavedImageCameraDiCommercio" [src]="srcSavedImageCameraDiCommercio" alt="file uploaded" />
      <p class="form-input-union-style border-0" *ngIf="!srcSavedImageCameraDiCommercio">
        Nessuna immagine correntemente salvata
      </p>
    </div>
    <ng-template [ngTemplateOutlet]="confirm_cancel_edit_attributes"></ng-template>

    <div class="mat-elevation-z8 mb-10 bg-white p-5">
      <form [formGroup]="cameraDiCommercioForm">
        <h3 class="text-lg font-bold">Modifica</h3>
        <div class="flex flex-col gap-2 justify-end">
          <label for="email">Email</label>
          <input type="email" class="form-input-union-style p-2" id="email" formControlName="email" />
          <p class="text-xs text-rosso-scaduto" [class.invisible]="checkFieldIsValid(cameraDiCommercioForm, 'email')">
            Inserisci una email valida
          </p>
        </div>
        <div class="flex flex-col gap-2 justify-end" *ngIf="cameraDiCommercioForm.controls.email.dirty">
          <label for="emailCheck">Email controllo</label>
          <input type="email" class="form-input-union-style p-2" id="emailCheck" formControlName="emailCheck" />
          <p class="text-xs text-rosso-scaduto" [class.invisible]="cameraDiCommercioForm.value.email === cameraDiCommercioForm.value.emailCheck">
            Inserisci nuovamente l'email
          </p>
        </div>
        <div
        *ngIf="showAbilitataAlleNotifiche()"
          class="flex flex-row gap-2 items-center border-2 border-solid border-innexta-cyan rounded-md p-1">
          <label for="notifiche" class="whitespace-nowrap">Abilitata alle notifiche</label>
          <input type="checkbox" class="w-6 h-full ml-auto min-h-[50px]" id="notifiche" formControlName="notifiche" />
        </div>
      </form>
      <div class="flex flex-col gap-2 justify-end mt-5">
        <label for="imageCCIAA">Immagine CCIAA</label>
        <input type="file" class="form-input-union-style p-2" id="imageCCIAA" accept="image/png, image/jpeg"
          (input)="uploadFile($event)" [(ngModel)]="logoCCIAA" />
        <img class="form-input-union-style border-rosso-scaduto sm:max-w-[500px] h-auto sm:max-h-[500px] m-auto"
          *ngIf="logoCCIAAPreview" [src]="logoCCIAAPreview" alt="preview file upload" />
        <br />
      </div>
    </div>

    <ng-template [ngTemplateOutlet]="confirm_cancel_edit_attributes"></ng-template>
  </div>
</div>

<ng-template #confirm_cancel_edit_attributes>
  <div class="flex flex-wrap gap-5">
    <button class="bg-innexta-fucsia text-white rounded-md p-2 hover:shadow-2xl"
      [class.!bg-gray-500]="isNotEdited() || !canSave()" [class.cursor-default]="isNotEdited()"
      (click)="saveAttributes()">
      Conferma modifica
    </button>
    <button class="bg-innexta-cyan text-white rounded-md p-2 hover:shadow-2xl" [class.cursor-default]="isNotEdited()"
      [class.!bg-gray-500]="isNotEdited()" (click)="resetAttributes()">
      Annulla modifiche
    </button>
  </div>
</ng-template>