import { Component, OnInit, ViewChild } from '@angular/core';
import { TipologicaAdmin, findTipologicaById } from '../costanti_tipologiche';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { APP_TITLE } from 'src/app/app.settings';
import { TipologicheService } from 'src/app/core/services/tipologiche/tipologiche.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { GoBackGuardDialogComponent } from '../../shared/go-back-btn/go-back-guard-dialog/go-back-guard-dialog.component';
import { CPagination } from 'src/app/core/models/pagination.model';
import {forkJoin, map} from "rxjs";
const guardMessage = 'Sei sicuro di voler andare avanti ? Le modifiche non salvate andranno perse.';

@Component({
  selector: 'app-tipologiche-list-specific',
  templateUrl: './tipologiche-list-specific.component.html',
  styleUrls: ['./tipologiche-list-specific.component.scss'],
})
export class TipologicheListSpecificComponent implements OnInit {
  tipologica?: TipologicaAdmin;
  dataSource: any[] = [];
  displayedColumns: string[] = ['name', 'cameraAbilitata', 'cameraAccreditata'];
  listaAttributiModificatiAbilitate: Map<number | string, boolean> = new Map();
  listaAttributiModificatiAccreditate: Map<number | string, boolean> = new Map();
  hideGoBackButton = false;
  pagination?: CPagination;
  pagination_current_page = 1;

  search_value = '';
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  onPaginateChange(event: PageEvent) {
    const changePage = () => {
      this.pagination_current_page = event.pageIndex + 1;

      this.getTipologicaAttributes();
      this.listaAttributiModificatiAbilitate.clear();
      this.listaAttributiModificatiAccreditate.clear();
    };
    if (this.isEmptyModifiedAttributesMap()) {
      changePage();
      return;
    }
    this.paginator.pageIndex = this.pagination_current_page - 1;

    if (!this.isEmptyModifiedAttributesMap()) {
      const dialogRef = this.dialog.open(GoBackGuardDialogComponent, {
        data: { guard_message: guardMessage },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result.action) changePage();
      });
    }
  }

  searchClick() {
    const searchPage = () => {
      this.getTipologicaAttributes();
      this.listaAttributiModificatiAccreditate.clear();
      this.listaAttributiModificatiAbilitate.clear();
      this.pagination_current_page = 1;
    };
    if (this.isEmptyModifiedAttributesMap()) {
      searchPage();
      return;
    }
    //Se ci sono modifiche non salvate chiede conferma
    const dialogRef = this.dialog.open(GoBackGuardDialogComponent, {
      data: { guard_message: guardMessage },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.action) searchPage();
    });
  }
  constructor(
    private route: ActivatedRoute,
    private title: Title,
    private tipologicheService: TipologicheService,
    private dialog: MatDialog
  ) {
    this.route.params.subscribe(params => {
      this.ngOnInit();
    });
  }

  buildSingleEditLink(row: any) {
    return `/admin/typological/${this.tipologica?.id}/${row.id}`;
  }
  ngOnInit(): void {
    this.listaAttributiModificatiAccreditate.clear();
    this.listaAttributiModificatiAbilitate.clear();
    const name = this.route.snapshot.paramMap.get('name');
    if (!name) return;
    this.tipologica = findTipologicaById(name);

    if (this.tipologica.hideGoBackButton) this.hideGoBackButton = true;
    else this.hideGoBackButton = false;

    this.title.setTitle(`Tipologica ${this.tipologica?.name} - ${APP_TITLE}`);
    if (this.tipologica!.campiLista) {
      this.displayedColumns = this.tipologica!.campiLista;
    }
    if (this.tipologica === undefined) return;
    this.getTipologicaAttributes();
  }
  getTipologicaAttributes() {
    this.tipologicheService
      .getTipologicaByModels(this.tipologica!.id, this.pagination_current_page,this.search_value)
      .subscribe((data: any) => {
        this.dataSource = data[this.tipologica!.id].items;
        this.pagination = {
          currentPage: data[this.tipologica!.id].currentPage,
          elementPerPage: data[this.tipologica!.id].elementPerPage,
          totalElement: data[this.tipologica!.id].totalElement,
          totalPage: data[this.tipologica!.id].totalPage,
        };
      });
  }
  renderText(row: any) {
    //Fix errore di nomenclatura colonna
    if (row.name) return row.name;
    if (row.descrizione) return row.descrizione;
    if (row.nome) return row.nome;
  }
  switchToggleClickAbilitata(event: any, idAttributo: number) {
    if (this.listaAttributiModificatiAbilitate.has(idAttributo)) {
      this.listaAttributiModificatiAbilitate.delete(idAttributo);
    } else this.listaAttributiModificatiAbilitate.set(idAttributo, event.checked);
  }
  switchToggleClickAccreditata(event: any, idAttributo: number) {
    if (this.listaAttributiModificatiAccreditate.has(idAttributo)) {
      this.listaAttributiModificatiAccreditate.delete(idAttributo);
    } else this.listaAttributiModificatiAccreditate.set(idAttributo, event.checked);
  }
  isEmptyModifiedAttributesMap() {
    return ((this.listaAttributiModificatiAbilitate.size === 0) && (this.listaAttributiModificatiAccreditate.size === 0));
  }
  resetAttributes() {
    if (this.isEmptyModifiedAttributesMap()) return;
    this.listaAttributiModificatiAbilitate.clear();
    this.listaAttributiModificatiAccreditate.clear();
    this.getTipologicaAttributes();
  }
  saveAttributes() {
    if (this.isEmptyModifiedAttributesMap()) return;
    let sources = [];
    if (this.listaAttributiModificatiAbilitate.size != 0)
      sources.push(this.tipologicheService.editMultipleAttributesTipologicaByMap(this.tipologica!.id, this.listaAttributiModificatiAbilitate));
    if (this.listaAttributiModificatiAccreditate.size != 0)
      sources.push(this.tipologicheService.editMultipleAttributesTipologicaByMap("CameraDiCommercio2", this.listaAttributiModificatiAccreditate));
    forkJoin(sources).subscribe((data: any) => {
      this.listaAttributiModificatiAbilitate.clear();
      this.listaAttributiModificatiAccreditate.clear();
      this.getTipologicaAttributes();
    });
  }
}
