import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_ENDPOINT } from '../../app.settings';
import { Ruolo, User } from 'src/app/core/models/user.model';
import { TipologicaCamereDiCommercio } from 'src/app/core/models/tipologiche.model';
import { CPagination } from 'src/app/core/models/pagination.model';

export interface ResponseRuoli {
  message: string;
  roles: Ruolo[];
}

export type ResponseUsersList  = CPagination & {
  items: User[];
}

export interface ResponseUserDetails {
  message: string;
  user: User;
}

export interface ResponseMessageOnly {
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  public getUsers(page:number=1, emailOrCognome="", ruoloId=-1, cameraDiCommercioId=-1) {
    let ruolo = `&ruolo=${ruoloId}`;
    if(ruoloId == -1){
      ruolo = "";
    }
    let cameraDiCommercio = `&cameraDiCommercio=${cameraDiCommercioId}`;
    console.log(cameraDiCommercioId);
    if(cameraDiCommercioId == -1){
      console.log("cameraDiCommercioId == -1");
      cameraDiCommercio = "";
    }
    console.log(`${API_ENDPOINT}/api/amministrazione/utenti?page=${page}&testo=${emailOrCognome}${ruolo}${cameraDiCommercio}`);
    return this.http.get<ResponseUsersList>(`${API_ENDPOINT}/api/amministrazione/utenti?page=${page}&testo=${emailOrCognome}${ruolo}${cameraDiCommercio}`);
  }

  public getUserDetails(user_uuid: string) {
    return this.http.get<ResponseUserDetails>(
      `${API_ENDPOINT}/api/amministrazione/utente/${user_uuid}`
    );
  }

  //Add or update user, depending on the parameter id
  public upsertUser(data: any): any {
    return this.http.post(`${API_ENDPOINT}/api/amministrazione/utente/upsert`, data);
  }

  public getRoles() {
    return this.http.get<ResponseRuoli>(`${API_ENDPOINT}/api/amministrazione/ruoli`);
  }

  public getCamereDiCommercio() {
    return this.http.get<TipologicaCamereDiCommercio[]>(`${API_ENDPOINT}/api/tipologiche/camere`);
  }
  public requestResetPassword(email: string) {
    return this.http.post<ResponseMessageOnly>(`${API_ENDPOINT}/api/reset-password`, { email });
  }

  //Used in recovery password and when is asked a new password via email
  public changePassword(password: string, token: string) {
    return this.http.post<ResponseMessageOnly>(`${API_ENDPOINT}/api/cambia-password`, {
      password,
      token,
    });
  }
}
