<!-- div class="custom-container flex flex-col rounded-3xl my-8 shadow-custom-1">
  <div class="rounded-3xl bg-innexta-gradient flex justify-between items-center gap-5 p-4">
    <h1 class="text-white text-3xl font-bold text-center w-full">Statistiche</h1>
  </div>
</div -->

<div class="custom-container flex flex-col rounded-3xl my-8 shadow-custom-1">
  <div
    class="rounded-t-3xl bg-innexta-gradient p-8 flex flex-wrap justify-center items-center gap-5">
    <h1 class="text-white text-3xl font-bold">Statistiche</h1>
    <p *ngIf="admin" class="font-light text-sm text-white">Cambia la visualizzazione dei grafici selezionando i filtri</p>
  </div>
  <!-- div *ngIf="!admin" class="rounded-3xl bg-innexta-gradient flex justify-between items-center gap-5 p-4">
    <h1 class="text-white text-3xl font-bold text-center w-full">Statistiche</h1>
  </div -->
  <div class="rounded-b-3xl bg-white p-8">
    <div *ngIf="admin" #filtro class="flex flex-row gap-5 flex-wrap filtri-bandi" id="filtro_richieste" style="float: left">
      <app-filtro-ricerca-bandi-singolo
        *ngFor="let filter of filters"
        [filter]="filter"
        [changeFilterAttributeStatus]="changeFilterAttributeStatus.bind(this)"
        [values]="filter.values"
        [clickButtonResetSpecificFilter]="clickButtonResetSpecificFilter.bind(this)" />
    </div>
    <div class="flex flex-row gap-5 flex-wrap filtri-bandi" id="export_excel" style="float: right">
      <button (click)="clickDownloadXlsx()" class="small-action-button whitespace-nowrap text-center h-8">Export Excel<mat-icon class="bg-transparent">analytics</mat-icon></button>
    </div>
  </div>
</div>

<div *ngIf="admin" class="custom-container">
  <div class="flex sm:inline-flex custom-tab-parent" style="width: 100%">
    <div style="width: 50%"
      class="tab" [class.selected]="selectedTab == 'richieste'" (click)="selectedTab = 'richieste'">
      Richieste
    </div>
    <div style="width: 50%"
      class="tab" [class.selected]="selectedTab == 'richieste_complessive'" (click)="selectedTab = 'richieste_complessive'">
      Richieste complessive
    </div>
  </div>
  <div class="hidden" [class.!block]="selectedTab == 'richieste_complessive'" style="border:1px solid grey;">
    <div class="custom-container py-8">
      <div *ngIf="admin">
        <div *ngIf="richiesteCamera; else loadingSpace" echarts [options]="richiesteCamera" style="height:500px" (chartInit)="onChartInit($event)"></div>
        <br/>
        <div *ngIf="richiesteMeseAnno; else loadingSpace" echarts [options]="richiesteMeseAnno" style="height:500px" (chartInit)="onChartInit($event)"></div>
      </div>
    </div>
  </div>
</div>
<div class="custom-container">
  <div class="hidden" [class.!block]="selectedTab == 'richieste'" style="border:1px solid grey;">
    <div class="custom-container py-8">
      <div *ngIf="richiesteFinalita; else loadingSpace" echarts [options]="richiesteFinalita" style="height:500px" (chartInit)="onChartInit($event)"></div>
      <br/>
      <div *ngIf="richiesteDimensioneInvestimento; else loadingSpace" echarts [options]="richiesteDimensioneInvestimento" style="height:500px" (chartInit)="onChartInit($event)"></div>
      <br/>
      <div *ngIf="richiesteTipoSoggetto; else loadingSpace" echarts [options]="richiesteTipoSoggetto" style="height:500px" (chartInit)="onChartInit($event)"></div>
      <br/>
      <div *ngIf="richiesteSettore; else loadingSpace" echarts [options]="richiesteSettore" style="height:500px" (chartInit)="onChartInit($event)"></div>
      <br/>
      <div *ngIf="richiesteFatturato; else loadingSpace" echarts [options]="richiesteFatturato" style="height:500px" (chartInit)="onChartInit($event)"></div>
      <br/>
      <div *ngIf="richiesteStatoLavorazione; else loadingSpace" echarts [options]="richiesteStatoLavorazione" style="height:500px" (chartInit)="onChartInit($event)"></div>
    </div>
  </div>
</div>
<ng-template #loadingSpace>
  <div class="align-items-center justify-content-center flex"style="min-height:500px;">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
