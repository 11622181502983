export interface TipologicaAdmin {
  id: string;
  name: string;
  editable?: boolean;
  campiLista?: string[];
  hideGoBackButton?: boolean;
}

export const listTipologiche: TipologicaAdmin[] = [
  { name: 'Settore', id: 'Settore' },
  { name: 'Strategie', id: 'Finalita' },
  { name: 'Ambito', id: 'AmbitoAgevolazione' },
  { name: 'Continente', id: 'Continente' },
  { name: 'Nazione', id: 'Nazione' },
  { name: 'Regione', id: 'Regione' },
  { name: 'Provincia', id: 'Provincia' },
  { name: 'Tag', id: 'Tag' },
  { name: 'Dimensione Beneficiario', id: 'DimensioneBeneficiario' },
  { name: 'Beneficiario', id: 'Beneficiario' },
  { name: 'Soggetto Gestore', id: 'SoggettoGestore' },
  { name: 'Tipologia aiuto', id: 'TipologiaAgevolazione' },
];



export const findTipologicaById = (id: string):TipologicaAdmin => {

  if(id == 'CameraDiCommercio' )  {
    return   { name: 'Camere di commercio', hideGoBackButton:true,id: 'CameraDiCommercio', editable: true, campiLista: ['name', 'email','cameraAbilitata','cameraAccreditata','singleEdit'] };
  }
  return listTipologiche.find(t => t.id === id)!;
};
