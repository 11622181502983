import { Component } from '@angular/core';
import { GlobalSpinnerLoaderService } from './global-spinner-loader.service';

@Component({
  selector: 'app-spinner-loader',
  templateUrl: './spinner-loader.component.html',
  styleUrls: ['./spinner-loader.component.scss'],
})
export class SpinnerLoaderComponent {
  constructor(public globalSpinnerLoaderService: GlobalSpinnerLoaderService) {}
}
