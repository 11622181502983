<div class="w-screen h-screen flex flex-col justify-center items-center overflow-hidden gap-5">
  <img src="../../../assets/svg/logo-union-camere-testo-nero.svg" class="max-w-2xl mx-10" />
  <div class="w-full max-w-[950px]">
    <div
      class="flex flex-col m-4 md:m-0 md:flex-row rounded-[20px] overflow-hidden shadow-custom-1">
      <div
        class="bg-innexta-gradient flex-1 flex justify-center flex-col items-center md:rounded-l-[20px] md:clip-path-custom-user-login">
        <h3 class="text-white text-[30px] font-bold mt-14 mb-8">Portale Agevolazioni</h3>
        <img src="../../../assets/svg/immagine-persona-login.svg" class="h-[250px]" />
      </div>
      <div class="flex-1 rounded-r-[20px] bg-white">
        <form
          class="mx-20 flex flex-col h-5/6"
          [formGroup]="recoveryPasswordForm"
          (ngSubmit)="onSubmitRecoveryPassword()">
          <h3 class="text-innexta-cyan text-[28px] font-bold mt-14 text-center">
            Imposta nuova password
          </h3>

          <label for="password" class="label-style mt-5">Nuova password</label>
          <div class="input-password-style">
            <input
              [type]="showPassword ? 'text' : 'password'"
              formControlName="password"
              id="password"
              (ngModelChange)="renderPasswordStrength()" />

            <mat-icon class="showPasswordBtn" (click)="showPassword = !showPassword">{{
              showPassword ? 'visibility_off' : 'visibility'
            }}</mat-icon>
          </div>

          <div
            class="w-full border-2 border-solid border-innexta-cyan rounded-md my-2"
            *ngIf="
              recoveryPasswordForm.controls['password'].value &&
              recoveryPasswordForm.controls['password'].value.length > 0
            ">
            <div
              class="transition-all duration-300 h-3 rounded-sm"
              [class]="actualScore.bgColor + ' ' + actualScore.width"></div>
          </div>
          <p
            [class]="actualScore.textColor"
            *ngIf="recoveryPasswordForm.controls['password'].dirty">
            {{ actualScore.text }}
          </p>
          <ul>
            <li *ngFor="let suggestion of suggestions">{{ suggestion }}</li>
          </ul>
          <label for="password" class="label-style">Conferma password</label>
          <div class="input-password-style">
            <input
              [type]="showConfermaPassword ? 'text' : 'password'"
              formControlName="confermaPassword"
              id="confermaPassword" />

            <mat-icon
              class="showPasswordBtn"
              (click)="showConfermaPassword = !showConfermaPassword"
              >{{ showConfermaPassword ? 'visibility_off' : 'visibility' }}</mat-icon
            >
          </div>
          <p
            *ngIf="
              recoveryPasswordForm.controls['password'].dirty &&
              recoveryPasswordForm.controls['password'].value !=
                recoveryPasswordForm.controls['confermaPassword'].value
            "
            class="text-rosso-scaduto">
            La password non corrisponde
          </p>
          <div class="my-4">
            <button
              class="submit-btn disabled:bg-slate-500 disabled:text-slate-200"
              (click)="onSubmitRecoveryPassword()"
              [disabled]="!isFormValid() || success"
              *ngIf="!success">
              IMPOSTA NUOVA PASSWORD 
            </button>
          </div>
          <p class="text-rosso-scaduto" *ngIf="error">{{ error }}</p>

          <p *ngIf="success" class="text-green-500">Password impostata con successo</p>

          <div class="my-4" *ngIf="success">
            <button class="submit-btn pr-4 pl-2" routerLink="/signin">
              TORNA AL LOGIN
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
