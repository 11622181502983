import { Injectable } from '@angular/core';
import { UserSessionService } from '../user-session/user-session.service';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  constructor(
    private userSessionService: UserSessionService,
    private authService: AuthService
  ) {}

  public initializeApp(): Observable<void> {
    return new Observable(sub => {
      if (!this.userSessionService.isLogged()) {
        sub.complete();
        return;
      }
      this.authService.userData().subscribe({
        next: response => this.userSessionService.setUserData(response.user),
        error: error => {
          console.error("errore  inizializeapp",error);
          this.userSessionService.logout();
          //sub.error(error);
          sub.complete();
        },
        complete: () => sub.complete(),
      });
    });
  }
}
