<div
  class="custom-container flex flex-col rounded-3xl my-8 shadow-custom-1">
  <div
    class="rounded-3xl bg-innexta-gradient flex justify-between items-center gap-5 p-4">
    <app-go-back-btn/>
    <h1 class="text-white text-3xl font-bold text-center w-full" *ngIf="isEditExistentUser()">Modifica utente: {{ userDetail?.nome }} {{ userDetail?.cognome }}</h1>
    <h1 class="text-white text-3xl font-bold text-center w-full" *ngIf="isInsertNewUser()">Aggiungi utente</h1>
  </div>
</div>


<div class=" py-8 shadow-2xl">
  <div class="custom-container">
    <form class="bg-white p-4 rounded-md shadow-2xl">
      <div class="flex flex-col gap-5">
        <hr />
        <form autocomplete="off" class="flex flex-col gap-5" [formGroup]="userDetailsForm">
          <ng-template [ngTemplateOutlet]="confirm_add_edit_user"></ng-template>

          <div class="flex flex-row gap-2">
            <div class="flex flex-col gap-2 flex-1">
              <label for="nome">Nome*</label>
              <input
                type="text"
                formControlName="nome"
                id="nome"
                class="border-2 border-solid border-innexta-cyan rounded-md p-2 w-full"
                required />
              <p
                class="text-xs text-rosso-scaduto"
                *ngIf="
                  userDetailsForm.controls['nome'].touched &&
                  !userDetailsForm.controls['nome'].valid
                ">
                Inserisci il nome
              </p>
            </div>
            <div class="flex flex-col gap-2 flex-1">
              <label for="cognome">Cognome*</label>
              <input
                type="text"
                formControlName="cognome"
                id="cognome"
                class="border-2 border-solid border-innexta-cyan rounded-md p-2 w-full"
                required />
              <p
                class="text-xs text-rosso-scaduto"
                *ngIf="
                  userDetailsForm.controls['cognome'].touched &&
                  !userDetailsForm.controls['cognome'].valid
                ">
                Inserisci il cognome
              </p>
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <label for="email">Email*</label>
            <input
              type="email"
              formControlName="email"
              id="email"
              class="border-2 border-solid border-innexta-cyan rounded-md p-2 w-full"
              autocomplete="off"
              required />
            <p
              class="text-xs text-rosso-scaduto"
              *ngIf="
                userDetailsForm.controls['email'].touched &&
                !userDetailsForm.controls['email'].valid
              ">
              Inserisci una email valida
            </p>
          </div>
          <div class="flex flex-col gap-2">
            <div
              *ngIf="!checkIfUserIsLoggedOne()"
              class="flex flex-row gap-2 items-center border-2 border-solid border-innexta-cyan rounded-md p-1 min-h-[50px]">
              <label for="password" class="whitespace-nowrap"
                >Consenti creazione manuale password all’operatore</label
              >
              <input
                type="checkbox"
                class="w-6 h-full ml-auto min-h-[50px]"
                formControlName="generaPassword"
                (change)="passwordFromBackend()" />
            </div>
            <div class="flex flex-col gap-2 flex-1">
              <ng-container>
                <label for="passwordInput">Password</label>
                <div class="password-input">
                  <input
                  [type]="showPassword ? 'text' : 'password'"
                  formControlName="password"
                  id="passwordInput"
                  autocomplete="new-password"

                  (ngModelChange)="passwordLenghtError(); renderPasswordStrength()"
                  />
                  <mat-icon class="showPasswordBtn"(click)="showPassword =!showPassword">{{showPassword? 'visibility_off':'visibility'}}</mat-icon>
    
                </div>

                <div
                  class="h-5 w-full border-2 border-solid border-innexta-cyan rounded-md"
                  *ngIf="userDetailsForm.controls['password'].value.length > 0">
                  <div
                    class="transition-all duration-300 h-full rounded-sm"
                    [class]="actualScore.bgColor + ' ' + actualScore.width"></div>
                </div>
                <p
                  [class]="actualScore.textColor"
                  *ngIf="userDetailsForm.controls['password'].dirty">
                  {{ actualScore.text }}
                </p>
                <ul>
                  <li *ngFor="let suggestion of suggestions">{{ suggestion }}</li>
                </ul>
              </ng-container>
              <ng-container *ngIf="userDetailsForm.controls['password'].dirty">
                <label for="password">Conferma password</label>
                <div class="password-input">
                  <input
                  [type]="showPasswordConfirm ? 'text' : 'password'"
                  formControlName="passwordConfirm"
                  id="passwordConfirm"
                  autocomplete="off"

                  (ngModelChange)="passwordLenghtError(); renderPasswordStrength()"
                  />
                  <mat-icon class="showPasswordBtn"(click)="showPasswordConfirm =!showPasswordConfirm">{{showPasswordConfirm? 'visibility_off':'visibility'}}</mat-icon>
    
                </div>

               
                <p
                  class="text text-rosso-scaduto"
                  *ngIf="
                    userDetailsForm.controls['password'].dirty &&
                    userDetailsForm.controls['passwordConfirm'].invalid
                  ">
                  La password deve essere uguale a quella inserita sopra
                </p>
              </ng-container>
            </div>

            <p
              class="border-2 border-solid border-rosso-scaduto text-rosso-scaduto rounded-md p-1"
              *ngIf="errorPasswordOrCheckGeneraPassword()">
              Immetti una password o abilita la spunta per lasciare impostare la password all'utente
            </p>
          </div>

          <div class="flex flex-col gap-2" *ngIf="!checkIfUserIsLoggedOne()">
            <label for="ruolo">Ruolo</label>
            <select
              formControlName="ruolo"
              id="ruolo"
              class="border-2 border-solid border-innexta-cyan rounded-md p-2 w-full"
              required>
              <option [ngValue]="null" disabled>Seleziona un ruolo</option>
              <option *ngFor="let ruolo of ruoli" [ngValue]="ruolo.ruolo_id">
                {{ ruolo.ruolo_nome }}
              </option>
            </select>
            <p class="text-xs text-rosso-scaduto" *ngIf="!userDetailsForm.controls['ruolo'].valid">
              Seleziona un ruolo
            </p>
          </div>
          <div class="flex flex-col gap-2" [class.hidden]="!isOperatoreCamerale()">
            <label for="cameraDiCommercio">Camera di commercio associata</label>
            <select
              formControlName="cameraDiCommercio"
              id="cameraDiCommercio"
              class="border-2 border-solid border-innexta-cyan rounded-md p-2 w-full">
              <option [ngValue]="null" disabled>Seleziona una camera di commercio</option>
              <option *ngFor="let camera of camere_di_commercio" [ngValue]="camera.id">
                {{ camera.nome }}
              </option>
            </select>
            <p
              class="text-xs text-rosso-scaduto"
              *ngIf="userDetailsForm.value['cameraDiCommercio'] == null">
              Seleziona una camera di commercio
            </p>
          </div>
          <div class="flex flex-col gap-2" *ngIf="!checkIfUserIsLoggedOne()">
            <label for="statoAccount">Stato</label>
            <select
              formControlName="statoAccount"
              id="statoAccount"
              class="border-2 border-solid border-innexta-cyan rounded-md p-2 w-full">
              <option [ngValue]="true">Attivato</option>
              <option [ngValue]="false">Disattivato</option>
            </select>
          </div>
          <div
            [class.hidden]="!isOperatoreCamerale()"
            class="flex flex-row gap-2 items-center border-2 border-solid border-innexta-cyan rounded-md p-1">
            <label for="notifiche" class="whitespace-nowrap">Abilitato alle notifiche</label>
            <input type="checkbox" class="w-6 h-full ml-auto min-h-[50px]" id="notifiche" formControlName="notifiche" />
          </div>
          <div *ngIf="error">
            <p class="text-rosso-scaduto">{{ error }}</p>
          </div>
          <ng-template [ngTemplateOutlet]="confirm_add_edit_user"></ng-template>
        </form>
        <hr />
      </div>
    </form>
  </div>
</div>

<ng-template #confirm_add_edit_user>
  <div class="flex flex-col sm:flex-row gap-5">
    <button
      (click)="submitUserDetails()"
      [disabled]="!isFormValid()"
      class="bg-innexta-cyan flex-1 text-white disabled:disabled disabled:text-black font-bold py-2 px-4 rounded border-2 border-innexta-cyan border-solid hover:bg-white hover:text-innexta-cyan text-center">
      Salva utente
    </button>
    <div
      *ngIf="!checkIfUserIsLoggedOne()"
      class="flex-1"
      [matTooltip]="
        isDisabledSendMail()
          ? 'Disponibile solo se la password è cambiata o abilito il check che lascia imposta la password all\'utente. Inoltre è necessario che l\'utente sia attivato'
          : ''
      ">
      <button
        [disabled]="isDisabledSendMail()"
        (click)="submitUserDetailsAndSendEmail()"
        class="bg-innexta-fucsia w-full text-white disabled:disabled disabled:text-black font-bold py-2 px-4 rounded border-2 border-innexta-fucsia border-solid hover:bg-white hover:text-innexta-fucsia text-center">
        Salva utente e invia email
      </button>
    </div>
  </div>
</ng-template>
