<div class="custom-container flex flex-col rounded-3xl my-8 shadow-custom-1">
  <div class="rounded-3xl bg-innexta-gradient flex justify-between items-center gap-5 p-4">
    <h1 class="text-white text-3xl font-bold text-center w-full">Modifica filtri</h1>
  </div>
</div>

<div class="py-8 box-shadow-union">
  <div class="custom-container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 mb-10">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Modifica</th>
        <td mat-cell *matCellDef="let element">
          <a
            class="bg-innexta-cyan text-white bg font-bold py-1 px-2 rounded border-2 border-innexta-cyan border-solid hover:bg-white hover:text-innexta-cyan text-center"
            [routerLink]="'/admin/typological/' + element.id"
            >Modifica</a
          >
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
