import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Bando } from 'src/app/core/models/bando';
import { BandiService } from '../bandi.service';
import { convertYYYYMMDDToDDMMYYYY } from 'src/app/core/utils/date_utils';

@Component({
  selector: 'app-bandi-details',
  templateUrl: './bandi-details.component.html',
  styleUrls: ['./bandi-details.component.scss'],
})
export class BandiDetailsComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private bandiService: BandiService
  ) {
    this.newTab = this.route.snapshot.data['newTab'] ?? false;
  }
  newTab = false;
  bando_id: string | null = null;
  bando: Bando | null = null;

  parte_1_voci = [
    { id: 'ambitoAgevolazione', label: 'Ambito', icon: "share_location" },
    { id: 'beneficiari', label: 'Organizzazione', icon: 'corporate_fare' },
    { id: 'settori', label: 'Settore', icon: 'factory' },
    { id: 'finalita', label: 'Strategie', icon: 'chess' },
    { id: 'nazioni', label: 'Nazione', icon: 'location_on' },
    { id: 'regioni', label: 'Regione', icon: 'pin_drop' },
    { id: 'province', label: 'Provincia', icon: 'my_location' },
    { id: 'tags', label: 'Tags', icon: 'style' },
    {
      id: 'dataChiusura',
      label: 'Data chiusura',
      transformer: convertYYYYMMDDToDDMMYYYY,
      icon: 'alarm',
    },
    {
      id: 'dataApertura',
      label: 'Data apertura',
      transformer: convertYYYYMMDDToDDMMYYYY,
      icon: 'schedule',
    },
  ];
  ngOnInit(): void {
    this.bando_id = this.route.snapshot.paramMap.get('id');
    console.log(this.bando_id);
    if (this.bando_id == null) return;
    this.bandiService.getBandoById(this.bando_id).subscribe((data: any) => {
      console.log(data);
      this.bando = data.bando;
    });
  }

  public getBandoVoceById(parameter_voce: {
    transformer?: any;
    id: string;
  }): string | Date | number | null {
    const id = parameter_voce.id;
    if (this.bando == null) return null;
    const dato = (this.bando as any)[id] as string | Date | number | null | Array<string> | any;



    const lambda_return = (value: string | Date | number | null | Array<string> | any) => {
      //Se è presente applica la funzione di trasformazione
      if (parameter_voce.transformer) return parameter_voce.transformer(value);
      return value;
    };
    //Se è un array, applica la funzione di trasformazione a tutti gli elementi e li concatena da una virgola
    if (Array.isArray(dato)) {
      return dato.map(lambda_return).join(', ');
    }
    return lambda_return(dato);
  }
  getPdfSingoloBando(){
    this.bandiService.getPdfBando(this.bando!.id).subscribe(async (data: any) => {
      const base64Pdf = "data:application/pdf;base64,"+data.pdf;
      const rawpdf = await fetch(base64Pdf);
      const pdf = await rawpdf.blob();
      const url = window.URL.createObjectURL(pdf);
      window.open(url);
    });

  }
}
