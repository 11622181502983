import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { API_IMAGE_ENDPOINT_CAMERE } from 'src/app/app.settings';
import { TipologicaCamereDiCommercioEdit } from 'src/app/core/models/tipologiche.model';
import { TipologicheService } from 'src/app/core/services/tipologiche/tipologiche.service';
import { GoBackGuardDialogComponent } from '../../shared/go-back-btn/go-back-guard-dialog/go-back-guard-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-tipologiche-edit-camere-di-commercio',
  templateUrl: './tipologiche-edit-camere-di-commercio.component.html',
  styleUrls: ['./tipologiche-edit-camere-di-commercio.component.scss'],
})
export class TipologicheEditCamereDiCommercioComponent implements OnInit {
  cameraDiCommercioForm = this.fb.group({
    email: ['', Validators.compose([Validators.email])],
    emailCheck: ['', Validators.compose([Validators.email])],
    notifiche: [false],
  });
  idCameraDiCommercio?: number;
  logoCCIAAPreview?: string; //base64
  constructor(
    private fb: FormBuilder,
    private tipologicheService: TipologicheService,
    private activeRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.idCameraDiCommercio = +this.activeRoute.snapshot.params['id']!;
  }
  cameraDiCommercio?: TipologicaCamereDiCommercioEdit;

  srcSavedImageCameraDiCommercio?: string;
  buildSrcImageCameraDiCommercio() {
    this.srcSavedImageCameraDiCommercio = `${API_IMAGE_ENDPOINT_CAMERE}/${this.cameraDiCommercio!.id}.jpeg?${Date.now()}`; //cache buster
  }

  ngOnInit(): void {
    this.getCameraDati();
  }
  getCameraDati() {
    this.tipologicheService
      .getSingleTipologicaCameraDiCommercioById(this.idCameraDiCommercio!)
      .subscribe((data: any) => {
        console.log(data);
        this.cameraDiCommercio = data;
        this.cameraDiCommercioForm.patchValue({
          email: this.cameraDiCommercio!.email,
          notifiche: this.cameraDiCommercio!.notifiche,
        });
        if (this.cameraDiCommercio!.immagineAttiva) {
          this.buildSrcImageCameraDiCommercio();
        }
      });
  }
  isNotEdited() {
    if (this.cameraDiCommercioForm.dirty) {
      return false;
    }
    if (this.logoCCIAAPreview) return false;
    return true;
  }
  uploadFile(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.logoCCIAAPreview = reader.result as string;
    };
  }
  showAbilitataAlleNotifiche(): boolean {
    if (this.cameraDiCommercio?.email && this.cameraDiCommercio.email.length > 0) return true;

    if (this.cameraDiCommercioForm.controls.emailCheck.dirty) return true;

    return false;
  }
  canSave() {
    if (this.cameraDiCommercioForm.dirty) {
      if (!this.cameraDiCommercioForm.valid) {
        return false;
      }
      if (this.cameraDiCommercioForm.value.emailCheck === this.cameraDiCommercioForm.value.email) {
        return true;
      }

      if (this.cameraDiCommercioForm.value.notifiche !== this.cameraDiCommercio!.notifiche) {
        return true;
      }

      return false;
    }
    if (this.logoCCIAA) return true;
    return false;
  }
  async saveAttributes() {
    if (!this.canSave()) {
      return;
    }

    const reqBody: any = {};
    if (this.logoCCIAA) {
      reqBody['immagine'] = this.logoCCIAAPreview!.replaceAll(
        'data:image/png;base64,',
        ''
      ).replaceAll('data:image/jpeg;base64,', '');
    }
    if (this.cameraDiCommercioForm.value.emailCheck === this.cameraDiCommercioForm.value.email) {
      reqBody['email'] = this.cameraDiCommercioForm.value.email;
    }
    reqBody['notifiche'] = this.cameraDiCommercioForm.value.notifiche;
    if (
      this.cameraDiCommercioForm.value.email?.length === 0 &&
      this.cameraDiCommercioForm.value.notifiche
    ) {
      reqBody['notifiche'] = false;

      const dialogRef = this.dialog.open(GoBackGuardDialogComponent, {
        data: {
          guard_message:
            'Hai rimosso la mail, le notifiche verranno disabilitate. Sei sicuro di voler procedere?',
          confirm_button_text: 'Conferma',
          cancel_button_text: 'Annulla',
        },
      });
      const { action } = await lastValueFrom(dialogRef.afterClosed());
      if (action !== true) return;
    }

    if (this.cameraDiCommercioForm.valid) {
      this.tipologicheService
        .editSingleTipologicaCameraDiCommercioById(this.idCameraDiCommercio!, reqBody)
        .subscribe((data: any) => {
          console.log(data);
          this.cameraDiCommercioForm.patchValue({ emailCheck: '' });
          this.cameraDiCommercioForm.markAsPristine();
          this.logoCCIAAPreview = undefined;
          this.logoCCIAA = undefined;
          this.getCameraDati();
        });
    }
  }
  resetAttributes() {
    this.cameraDiCommercioForm.reset(
      {
        email: this.cameraDiCommercio!.email,
        emailCheck: null,
      },
      {
        emitEvent: false,
      }
    );
    this.cameraDiCommercioForm.markAsPristine();
    this.logoCCIAAPreview = undefined;
    this.logoCCIAA = undefined;
  }
  logoCCIAA?: any;
  submitted = false;
  checkFieldIsValid(form: any, formFieldName: string) {
    return form.controls[formFieldName].valid;
  }
}
