<header class="bg-white sticky  top-0 z-10 w-full">
  <div class="top-bar  w-full px-10 " style="box-shadow: 7px 7px 15px 5px rgba(0,0,0,0.6); ">
    <div class="justify-between flex items-center home-container m-auto w-full h-20">

        <mat-icon
        [fontIcon]="menuOpen ? 'close' : 'menu'"
        style="font-size: 30px; height: 30px; width: 30px"
        class="text-innexta-cyan font-bold cursor-pointer"
        (click)="toggleMenu()"
        >{{ menuOpen ? 'close' : 'menu' }}</mat-icon
      >
      <a class="mr-2 flex flex-row flex-wrap items-center" routerLink="/">
        <img
          class="w-[200px]"
          src="../../../assets/svg/logo-union-camere-testo-nero.svg"
          alt="logo unioncamere" />
      </a>

    </div>
  </div>


</header>

<div class="menu-mobile-overlay" [class.open]="menuOpen" (click)="closeMenu()"></div>
<div class="mobile-menu" [class.open]="menuOpen" >
  <menu class="flex gap-4 flex-col m-5">
    <div
      class="mb-4 flex flex-row items-center justify-between rounded-lg p-2 bg-innexta-gradient gap-4 shadow-xl"
      *ngIf="userSessionService.isUserLoaded()">
      <div class="flex flex-col">
        <p class="text-white text-xl">
          {{ userSessionService.getUserData().nome }}
          {{ userSessionService.getUserData().cognome }}
        </p>
        <p class="text-white" *ngIf="userSessionService.isConsulente()">Consulente</p>
        <p class="text-white" *ngIf="userSessionService.isOperatoreCamera()">Camera di commercio</p>
        <p class="text-white" *ngIf="userSessionService.isAdmin()">Amministratore</p>
      </div>
      <a
        (click)="userSessionService.logout()"
        class="bg-white rounded-full flex items-center justify-center p-1 h-[35px] shadow-xl cursor-pointer">
        <mat-icon
          fontIcon="logout"
          style="font-size: 30px; height: 30px; width: 30px"
          class="text-innexta-cyan hover:text-innexta-fucsia"
          >logout</mat-icon
        >
      </a>
    </div>

    <li *ngFor="let menu_el of menu">
      <a
        [routerLink]="menu_el.link"
        *ngIf="menu_el.link"
        routerLinkActive="active"
        class="text-xl font-bold cursor-pointer w-full block"
        [routerLinkActiveOptions]="{ exact: true }"
        (click)="closeMenu();forceReload(menu_el)"
        >{{ menu_el.name }}</a
      >
      <a
        *ngIf="!menu_el.link"
        class="text-xl font-bold cursor-pointer w-full flex items-center justify-between"
        (click)="menu_el.open = !menu_el.open"
        >{{ menu_el.name }} <mat-icon >{{menu_el.open ?'expand_less':'expand_more' }}</mat-icon></a
      >
      <ul *ngIf="menu_el.open" class="bg-slate-200  flex flex-col gap-5">
          <li *ngFor="let menu_el of menu_el.children">
            <a
            [routerLink]="menu_el.link"
            *ngIf="menu_el.link"
            class="text-xl font-bold cursor-pointer w-full block"
            (click)="closeMenu();forceReload(menu_el)"
            >{{ menu_el.name }}</a
          >
          </li>
      </ul>
    </li>
  </menu>
</div>
