import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import {StatisticheComponent} from "./statistiche.component";
import {NgxEchartsModule} from "ngx-echarts";

@NgModule({
  declarations: [
    StatisticheComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    NgxEchartsModule.forRoot({ echarts: () => import('echarts') })
  ],
  exports: [
    StatisticheComponent
  ]
})
export class StatisticheModule { }
