import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { API_ENDPOINT } from 'src/app/app.settings';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(private http: HttpClient ){

  }
  versionBackend: string = '';
    ngOnInit(): void {
      this.http.get(`${API_ENDPOINT}/api/versione`).subscribe((data:any)=>{
        this.versionBackend = data.versione;
      });
    }
}
