<div class="custom-container flex flex-col rounded-3xl my-8 shadow-custom-1">
  <div
    class="rounded-t-3xl bg-innexta-gradient p-8 flex flex-wrap justify-center items-center gap-5">
    <h1 class="text-white text-3xl font-bold">Utenti piattaforma</h1>
    <p class="font-light text-sm text-white"></p>
  </div>
  <div class="rounded-b-3xl bg-white p-4 flex flex-col lg:flex-row justify-center items-end gap-5">
    <div class="flex flex-col justify-between w-full">
      <label for="search_title" class="input-label-style text-black"
        >Filtro per Utente</label
      >

      <div class="input-search-style">
        <input id="search_title" [(ngModel)]="filterTextUser" (keyup.enter)="loadUsers()" placeholder="Filtra ..." />
        <button class="search-button" (click)="loadUsers()">cerca</button>
      </div>
    </div>
    <div class="flex flex-col justify-between w-full">
      <label for="filtraStatoRichieste" class="input-label-style text-black"
        >Filtra per ruolo</label
      >
      <mat-select
        class="input-style shadow-none mt-0"
        id="filtraStatoRichieste"
        [ngModel]="filterRoleId"
        (ngModelChange)="filterRoleId = +$event; loadUsers()"
       >
        <mat-option *ngFor="let el of listaRuoli" [value]="el.id">{{ el.nome }}</mat-option>
      </mat-select>
    </div>

    <div class="flex flex-col justify-between w-full ">
      <label class="input-label-style text-black">Filtra camera di commercio</label>
      <mat-select
        class="input-style shadow-none mt-0"
        
        (ngModelChange)="filterCameraDiCommercioId = +$event; loadUsers()"
        [ngModel]="filterCameraDiCommercioId">
        <mat-option
          ><ngx-mat-select-search
            ngModel
            (ngModelChange)="searchCameraDiCommercio($event)"
            noEntriesFoundLabel="Nessun risultato"
            placeholderLabel="Cerca..."></ngx-mat-select-search
        ></mat-option>
        <mat-option *ngFor="let el of listaCamereDiCommercioSublist" [value]="el.id">{{
          el.nome
        }}</mat-option>
      </mat-select>
    </div>
    <div class="w-full lg:max-w-[10rem]">
      <button class="small-action-button w-full h-8 whitespace-nowrap" (click)="filterCameraDiCommercioId = -1;filterTextUser = ''; filterRoleId=-1; loadUsers()">Pulisci filtri</button>
    </div>

  </div>
</div>

<div class="py-8 box-shadow-union">
  <div class="custom-container">
    <mat-paginator
      *ngIf="pagination"
      [hidePageSize]="true"
      [pageSize]="pagination.elementPerPage"
      [pageIndex]="pagination.currentPage - 1"
      [length]="pagination.totalElement"
      [showFirstLastButtons]="true"
      (page)="onPaginateChange($event)"></mat-paginator>

    <table mat-table [dataSource]="users" class="mat-elevation-z8 mb-10 table-user-list">
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let element">
          <p class="mobile-title">Email:</p>
          {{ element.email }}
        </td>
      </ng-container>

      <ng-container matColumnDef="nome">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let element">
          <p class="mobile-title">Nome:</p>
          {{ element.nome }}
        </td>
      </ng-container>

      <ng-container matColumnDef="cognome">
        <th mat-header-cell *matHeaderCellDef>Cognome</th>
        <td mat-cell *matCellDef="let element">
          <p class="mobile-title">Cognome:</p>
          {{ element.cognome }}
        </td>
      </ng-container>

      <ng-container matColumnDef="ruolo">
        <th mat-header-cell *matHeaderCellDef>Ruolo</th>
        <td mat-cell *matCellDef="let element">
          <p class="mobile-title">Ruolo:</p>

          {{ element.ruoli[0]?.ruolo_nome ?? 'Nessun ruolo' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="cameraDiCommercio">
        <th mat-header-cell *matHeaderCellDef>Camera di Commercio</th>
        <td mat-cell *matCellDef="let element">
          <p class="mobile-title">Camera di Commercio:</p>

          {{ element.cameraDiCommercio?.nome ?? 'Nessuna' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="stato">
        <th mat-header-cell *matHeaderCellDef>Stato</th>
        <td mat-cell *matCellDef="let element">
          <p class="mobile-title">Stato:</p>

          {{ element.attivo ? 'Attivo' : 'Disattivato' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          <a
            class="bg-innexta-cyan text-white rounded-full inline-flex items-center justify-center p-1 border-2 border-innexta-cyan border-solid hover:bg-white hover:text-innexta-cyan text-center cursor-pointer"
            routerLink="/admin/users/new">
            Aggiungi <mat-icon fontIcon="add">add</mat-icon>
          </a>
        </th>

        <td mat-cell *matCellDef="let element">
          <a
            class="bg-innexta-cyan text-white bg font-bold m-2 py-1 px-2 rounded border-2 border-innexta-cyan border-solid hover:bg-white hover:text-innexta-cyan text-center"
            [routerLink]="['/admin/users', element.uuid]"
            >Modifica</a
          >
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [class.animate-bouncecustom]="row.email === user_changed_email"></tr>
    </table>
    <mat-paginator
      *ngIf="pagination"
      [hidePageSize]="true"
      [pageSize]="pagination.elementPerPage"
      [pageIndex]="pagination.currentPage - 1"
      [length]="pagination.totalElement"
      [showFirstLastButtons]="true"
      (page)="onPaginateChange($event)"></mat-paginator>
  </div>
</div>
