import { Component, OnInit } from '@angular/core';
import { ENVIRONMENT_NAME } from './app.settings';

@Component({
  selector: 'app-root',
  template: `<app-spinner-loader/><router-outlet/>`,
  styleUrls: [],
})
export class AppComponent implements OnInit {
  ngOnInit(): void {
    console.log('ENV NAME', ENVIRONMENT_NAME);
  }
}
