<div class="w-screen h-screen flex flex-col justify-center items-center overflow-hidden gap-5">
  <img src="../../../assets/svg/logo-union-camere-testo-nero.svg" class="max-w-2xl mx-10" />
  <div class="w-full max-w-[950px]">
    <div
      class="flex flex-col m-4 md:m-0 md:flex-row rounded-[20px] overflow-hidden shadow-custom-1">
      <div
        class="bg-innexta-gradient flex-1 flex justify-center flex-col items-center md:rounded-l-[20px] md:clip-path-custom-user-login">
        <h3 class="text-white text-[30px] font-bold mt-14 mb-8">Portale Agevolazioni</h3>
        <img src="../../../assets/svg/immagine-persona-login.svg" class="h-[250px]" />
      </div>
      <div class="flex-1 rounded-r-[20px] bg-white">
        <form
          class="mx-20 flex flex-col h-5/6"
          [formGroup]="recoveryPasswordForm"
          (ngSubmit)="onSubmitRecoveryPassword()">
          <h3 class="text-innexta-cyan text-[30px] font-bold mt-14 text-center">Recupera password</h3>
          <label for="email" class="label-style">Email</label>
          <input type="text" formControlName="email" id="email" class="input-style" />
          <p
            *ngIf="
              recoveryPasswordForm.controls.email.dirty &&
              recoveryPasswordForm.controls.email.invalid
            "
            class="text-rosso-scaduto">
            La mail non è valida
          </p>

          <label for="confermaEmail" class="label-style">Conferma email</label>
          <input
            type="text"
            formControlName="confermaEmail"
            id="confermaEmail"
            class="input-style" />
          <p *ngIf="!checkConfirmEmail()" class="text-rosso-scaduto">
            La mail di conferma non combacia
          </p>

          <div class="my-4">
            <button
              class="submit-btn disabled:bg-slate-500 disabled:text-slate-200"
              (click)="onSubmitRecoveryPassword()"
              [disabled]="submitted || !isFormValid()">
              INVIA EMAIL DI RECUPERO
            </button>
          </div>
          <div class="my-4">
            <button class="submit-btn pr-4 pl-2" routerLink="/signin">
            TORNA AL LOGIN
            </button>
          </div>
        </form>
        <p *ngIf="confirmMessage" class="mx-20 mt-5 text-green-500">{{ confirmMessage }}</p>
        <p class="text-rosso-scaduto mx-20 mt-5" *ngIf="error">{{ error }}</p>
      </div>
    </div>
  </div>
</div>
