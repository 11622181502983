import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RUOLO_ADMIN, RUOLO_CONSULENTE, RUOLO_OPERATORE } from 'src/app/core/costanti_ruoli';
import { UserSessionService } from 'src/app/core/services/user-session/user-session.service';

interface MenuItem {
  name: string;
  link?: string;
  children?: MenuItem[];
  open?: boolean;
  force?: boolean;
  roles?: string[];
}

const menu_tree_clear = (menu: MenuItem[], userRole: string, accreditata: boolean) => {
  menu.forEach((item,k) => {
    if (menu[k].name == 'Richiedi agevolazioni' && !accreditata && userRole != 'Amministratore'){
      delete menu[k];
      return;
    }
    if(item.roles && !item.roles.some(role => role === userRole)) {
      delete menu[k];
      return;
    }
    if (item.children) {
      item.children = menu_tree_clear(item.children, userRole, accreditata);
    }

  });
  menu = [...menu].filter(item => item !== undefined);
  return menu;
};


const menu_admin: MenuItem[] = [
  {
    name: 'Utenti',
    link: '/admin/users',
  },
  {
    name: 'Modifica filtri',
    link: '/admin/typological',
  },
  {
    name: 'Abilita Camera di Commercio',
    link: '/admin/typological/CameraDiCommercio'
  }
];

const menu_operatori: MenuItem[] = [
  {
    name: 'Richieste',
    link: '/gestione-richieste',
    roles: [RUOLO_OPERATORE,RUOLO_ADMIN],
  },
  {
    name: 'Ricerca bandi',
    link: '/bandi',
    roles: [RUOLO_OPERATORE,RUOLO_ADMIN,RUOLO_CONSULENTE],
  },
  {
    name: 'Richiedi agevolazioni',
    link: '/richiedi-agevolazioni',
    force: true,
    roles: [RUOLO_OPERATORE,RUOLO_ADMIN],
  },
  {
    name: 'Statistiche',
    link: '/statistiche',
    force: true,
    roles: [RUOLO_OPERATORE,RUOLO_ADMIN],
  },
];

const original_menu: MenuItem[] = [
  {
    name: 'Home',
    link: '/home',
  },

  { name: 'InfoBandi', children: menu_operatori, roles: undefined},
  { name: 'Amministrazione', children: menu_admin, roles: [RUOLO_ADMIN] },
];


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit{
  constructor(public userSessionService: UserSessionService, private router: Router) {

  }
  ngOnInit(): void {
    const user_role = this.userSessionService.role;
    const accreditata = this.userSessionService.accreditata;
    console.log("user_role: ", user_role);
    this.menu =  menu_tree_clear(structuredClone(original_menu),user_role,accreditata); // deep copy of original_menu and then clear it of unwanted items
    console.log('menu', this.menu);

  }

  forceReload(menuItem: MenuItem) {
    console.log('forceReload', menuItem);
    if(!menuItem.force) return;
    if(!menuItem.link) return;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([menuItem.link]);
    });
  }

  menuOpen = false;
  closeMenu() {
    this.menuOpen = false;
    this.menu.forEach(item => {
      if (item.open) item.open = false;
    });
  }
  openMenu() {
    this.menuOpen = true;
  }
  toggleMenu() {
    if (this.menuOpen) this.closeMenu();
    else this.openMenu();
  }
  menu : MenuItem[] = [];

}
