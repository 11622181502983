import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalSpinnerLoaderService {
  public isEnable = false;
  enableSpinner() {
    this.isEnable = true;
  }
  disableSpinner() {
    this.isEnable = false;
  }
}
