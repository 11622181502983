<div class="custom-container flex flex-col rounded-3xl overflow-hidden my-8 shadow-custom-1">
  <div class="bg-innexta-gradient flex justify-between items-center gap-5 p-4">
    <app-go-back-btn
      [guard_function]="isEmptyModifiedAttributesMap.bind(this)"
      *ngIf="!hideGoBackButton" />
    <h1 class="text-innexta-cyan text-3xl font-bold text-center w-full">
      Modifica {{ tipologica?.name }}
    </h1>
  </div>
  <div class="bg-white p-4 flex flex-col lg:flex-row justify-center items-center gap-5">
    <div class="flex flex-col justify-between w-full max-w-md">
      <label for="search_title" class="input-label-style text-black whitespace-nowrap"
        >Filtra attributo</label
      >

      <div class="input-search-style">
        <input id="search_title" name="filtra attributo" placeholder="Filtra ..."[(ngModel)]="search_value" (keyup.enter)="searchClick()" />
        <button class="search-button" (click)="searchClick()">cerca</button>
      </div>
    </div>
  </div>
</div>

<div class="py-8 box-shadow-union">
  <div class="custom-container flex flex-col gap-5">
    <ng-template [ngTemplateOutlet]="confirm_cancel_edit_attributes"></ng-template>

    <mat-paginator
      *ngIf="pagination"
      [hidePageSize]="true"
      [pageSize]="pagination.elementPerPage"
      [pageIndex]="pagination.currentPage - 1"
      [length]="pagination.totalElement"
      [showFirstLastButtons]="true"
      (page)="onPaginateChange($event)"></mat-paginator>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 mb-10">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let element">{{ renderText(element) }}</td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef class="hidden sm:table-cell">Email</th>
        <td mat-cell *matCellDef="let element" class="hidden sm:table-cell">
          {{ element.email ?? '-' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="cameraAbilitata">
        <th mat-header-cell *matHeaderCellDef>Abilitata</th>
        <td mat-cell *matCellDef="let element">
          <mat-slide-toggle
            (change)="switchToggleClickAbilitata($event, element.id)"
            [checked]="element.attivo"
            color="primary"></mat-slide-toggle>
        </td>
      </ng-container>
      <ng-container matColumnDef="cameraAccreditata">
        <th mat-header-cell *matHeaderCellDef>Accreditata</th>
        <td mat-cell *matCellDef="let element">
          <mat-slide-toggle
            (change)="switchToggleClickAccreditata($event, element.id)"
            [checked]="element.accreditata"
            color="primary"></mat-slide-toggle>
        </td>
      </ng-container>
      <ng-container matColumnDef="singleEdit">
        <th mat-header-cell *matHeaderCellDef>Stato</th>
        <td mat-cell *matCellDef="let element">
          <a
            *ngIf="tipologica?.editable === true"
            class="bg-innexta-cyan text-white bg font-bold py-1 px-2 rounded border-2 border-innexta-cyan border-solid hover:bg-white hover:text-innexta-cyan text-center"
            [routerLink]="buildSingleEditLink(element)"
            >Modifica</a
          >
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      *ngIf="pagination"
      [hidePageSize]="true"
      [pageSize]="pagination.elementPerPage"
      [pageIndex]="pagination.currentPage - 1"
      [length]="pagination.totalElement"
      [showFirstLastButtons]="true"
      (page)="onPaginateChange($event)"></mat-paginator>
    <ng-template [ngTemplateOutlet]="confirm_cancel_edit_attributes"></ng-template>
  </div>
</div>

<ng-template #confirm_cancel_edit_attributes>
  <div class="flex flex-wrap gap-5">
    <button
      class="bg-innexta-fucsia text-white rounded-md p-2 hover:shadow-2xl"
      [class.!bg-gray-500]="isEmptyModifiedAttributesMap()"
      [class.cursor-default]="isEmptyModifiedAttributesMap()"
      (click)="saveAttributes()">
      Conferma modifica
    </button>
    <button
      class="bg-innexta-cyan text-white rounded-md p-2 hover:shadow-2xl"
      [class.cursor-default]="isEmptyModifiedAttributesMap()"
      [class.!bg-gray-500]="isEmptyModifiedAttributesMap()"
      (click)="resetAttributes()">
      Annulla modifiche
    </button>
  </div>
</ng-template>
