import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { UserSessionService } from 'src/app/core/services/user-session/user-session.service';
import { UserService } from '../user-admin.service';
import { TipologicaCamereDiCommercio } from 'src/app/core/models/tipologiche.model';
import { Ruolo, User } from 'src/app/core/models/user.model';
import {
  ScoreRender,
  minimumPasswordScore,
  passwordStrength,
  score0,
} from 'src/app/core/utils/password_security';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/core/services/auth/auth.service';

interface ErrorMessage {
  error: {
    message: string;
  };
}

const RUOLO_OPERATORE_CAMERA_DI_COMMERCIO = 2;

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit {
  user_uuid: string | null = null;
  min_password_length = 8;
  checkIfUserIsLoggedOne() {
    if (this.user_uuid == null) return false;

    const data = this.UserSessionService.getUserData();

    if (data?.uuid) {
      return data.uuid === this.user_uuid;
    }
    return false;
  }

  isInsertNewUser() {
    if (this.route.snapshot.data['isEditingMyAccount']) {
      return false;
    }
    return this.user_uuid == null;
  }
  isEditExistentUser() {
    if (this.route.snapshot.data['isEditingMyAccount']) {
      return true;
    }
    return this.user_uuid != null;
  }

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private userAdminService: UserService,
    private router: Router,
    private UserSessionService: UserSessionService,
    private snackBar: MatSnackBar,
    private authService: AuthService
  ) {
    this.user_uuid = this.route.snapshot.paramMap.get('uuid');
    if (this.route.snapshot.data['isEditingMyAccount']) {
      this.user_uuid = this.UserSessionService.getUserData()!.uuid;
    }
  }
  public passwordFromBackend() {
    const valueCheck = this.userDetailsForm.get('generaPassword')?.value;
    if (valueCheck) {
      this.userDetailsForm.get('password')!.disable();
      this.userDetailsForm.get('passwordConfirm')!.disable();

      this.userDetailsForm.patchValue(
        {
          password: '',
          passwordConfirm: '',
        },
        { emitEvent: false }
      );

      this.userDetailsForm.controls['password'].setValidators([]);
      this.userDetailsForm.controls['passwordConfirm'].setValidators([]);
      this.userDetailsForm.controls['password'].updateValueAndValidity();
      this.userDetailsForm.controls['passwordConfirm'].updateValueAndValidity();
      this.userDetailsForm.controls['password'].markAsPristine();
      this.userDetailsForm.controls['passwordConfirm'].markAsPristine();

      this.userDetailsForm.setValidators([]);
      this.userDetailsForm.updateValueAndValidity();
      this.actualScore = score0;
      this.suggestions = [];
    } else {
      this.userDetailsForm.get('password')!.enable();
      this.userDetailsForm.get('passwordConfirm')!.enable();

      this.userDetailsForm.controls['password'].markAsPristine();
      this.userDetailsForm.controls['passwordConfirm'].markAsPristine();

      if (this.isInsertNewUser()) {
        this.userDetailsForm.controls['password'].setValidators([
          Validators.required,
          Validators.minLength(this.min_password_length),
        ]);
        this.userDetailsForm.controls['passwordConfirm'].setValidators([
          Validators.required,
          Validators.minLength(this.min_password_length),
        ]);
      }

      this.userDetailsForm.controls['password'].updateValueAndValidity();
      this.userDetailsForm.controls['passwordConfirm'].updateValueAndValidity();

      this.userDetailsForm.setValidators([
        this.ConfirmedValidator('password', 'passwordConfirm') as any,
      ]);
    }
  }

  ngOnInit(): void {
    forkJoin({
      roles: this.userAdminService.getRoles(),
      camere_di_commercio: this.userAdminService.getCamereDiCommercio(),
    }).subscribe(res => {
      this.ruoli = res.roles.roles;
      this.camere_di_commercio = res.camere_di_commercio;
    });
    if (this.isInsertNewUser()) {
      this.userDetailsForm.patchValue({
        generaPassword: true,
      }, { emitEvent: false });
      this.passwordFromBackend();
      return
    }
    this.userAdminService.getUserDetails(this.user_uuid!).subscribe(res => {
      this.userDetail = res.user;
      const user = res.user;
      const userDetail = {
        nome: user.nome,
        cognome: user.cognome,
        email: user.email,
        ruolo: user.ruoli![0]?.ruolo_id,
        cameraDiCommercio: user.cameraDiCommercio?.id,
        statoAccount: user.attivo,
        notifiche: user.notifiche,
      };
      console.log(userDetail);
      if (user.cameraDiCommercio) this.camere_di_commercio.push(user.cameraDiCommercio!);
      //remove duplicate with hashmap
      this.camere_di_commercio = [
        ...new Map(this.camere_di_commercio.map(item => [item.id, item])).values(),
      ];
      this.userDetailsForm.patchValue({ ...userDetail }, { emitEvent: false });

      this.userDetailsForm.controls['password'].setValidators([]);
      this.userDetailsForm.controls['passwordConfirm'].setValidators([]);
      this.userDetailsForm.controls['password'].updateValueAndValidity({ emitEvent: false });
      this.userDetailsForm.controls['passwordConfirm'].updateValueAndValidity({ emitEvent: false });
      this.isOperatoreCamerale();
      Object.freeze(this.userDetail);
    });
  }
  userDetail: User | undefined;
  error: string | null = null;
  submitted = false;

  userDetailsForm = this.fb.group(
    {
      //nome and cognome are required
      nome: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      cognome: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: [
        '',
        Validators.compose([Validators.required, Validators.minLength(this.min_password_length)]),
      ],
      passwordConfirm: [
        '',
        Validators.compose([Validators.required, Validators.minLength(this.min_password_length)]),
      ],
      generaPassword: [false],
      ruolo: [null],
      cameraDiCommercio: [null],
      statoAccount: [false],
      notifiche: [false],
    },
    {
      validators: this.ConfirmedValidator('password', 'passwordConfirm'),
    }
  );
  ruoli: Ruolo[] = [];
  camere_di_commercio: TipologicaCamereDiCommercio[] = [];
  submitUserDetailsAndSendEmail() {
    this.submitUserDetails(true);
  }

  submitUserDetails(sendMail = false) {
    this.submitted = true;
    this.error = null;

    if (this.userDetailsForm.invalid) return;
    const createOrUpdateReqBody = {
      nome: this.userDetailsForm.value.nome,
      cognome: this.userDetailsForm.value.cognome,
      email: this.userDetailsForm.value.email,
      password: this.userDetailsForm.value.password,
      ruolo: this.userDetailsForm.value.ruolo,
      camera: this.userDetailsForm.value.cameraDiCommercio,
      attivo: this.userDetailsForm.value.statoAccount,
      generaPassword: Boolean(this.userDetailsForm.value.generaPassword),
      inviaEmail: sendMail,
      id: this.userDetail?.uuid ?? null,
      notifiche: this.userDetailsForm.value.notifiche,
    };


    if (createOrUpdateReqBody.ruolo !== RUOLO_OPERATORE_CAMERA_DI_COMMERCIO) createOrUpdateReqBody.notifiche = false;
    if (createOrUpdateReqBody.camera === null) delete createOrUpdateReqBody.camera;
    
    if(createOrUpdateReqBody.attivo === false) createOrUpdateReqBody.notifiche = false;

    if (this.isEditExistentUser()) {
      if (createOrUpdateReqBody.password === '' || createOrUpdateReqBody.password === null)
        delete createOrUpdateReqBody.password;
      createOrUpdateReqBody.id = this.user_uuid;
      if (this.userDetail && this.userDetail.email === createOrUpdateReqBody.email) {
        delete createOrUpdateReqBody.email;
      }
    }
    this.userAdminService.upsertUser(createOrUpdateReqBody).subscribe(
      () => {
        this.error = null;

        if (this.checkIfUserIsLoggedOne()) {
          this.authService.userData().subscribe(data => {
            this.UserSessionService.setUserData(data.user);
          });
        }

        if (this.checkIfUserIsLoggedOne() && this.route.snapshot.data['isEditingMyAccount']) {
          this.router.navigate(['home']);
          this.snackBar.open('Modifiche salvate con successo', 'Chiudi', {
            duration: 3000,
            panelClass: ['white-custom'],
          });
        } else {
          this.returnToUserList(this.userDetailsForm.value.email);
        }
      },
      (err: ErrorMessage) => {
        this.error = err.error.message;
      }
    );
  }

  private returnToUserList(email: string) {
    this.router.navigate(['admin', 'users'], { state: { email_user: email } });
  }

  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors['confirmedValidator']) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
  isOperatoreCamerale() {
    const ruolo = this.userDetailsForm.get('ruolo')?.value;
    const ruolo_id = parseInt(ruolo);
    const isOperatoreCamerale = ruolo_id === RUOLO_OPERATORE_CAMERA_DI_COMMERCIO;

    if (isOperatoreCamerale) {
      this.userDetailsForm.controls['cameraDiCommercio'].setValidators([Validators.required]);
    } else {
      this.userDetailsForm.controls['cameraDiCommercio'].setValidators([]);
      this.userDetailsForm.patchValue({ cameraDiCommercio: null }, { emitEvent: false });
    }
    this.userDetailsForm.controls['cameraDiCommercio'].updateValueAndValidity({ emitEvent: true });
    return isOperatoreCamerale;
  }
  isDisabledSendMail() {
    //Se il form è invalido non posso inviare la mail
    const formInvalid = this.userDetailsForm.invalid;

    //Importante mantenere solo l'uguaglianza non stretta

    const statoAccount = this.userDetailsForm.value.statoAccount == true;
    const accountDisattivato = !statoAccount;

    if (formInvalid) {
      return true;
    }

    if (accountDisattivato) {
      return true;
    }
    if (this.isEditExistentUser()) {
      const errorPassword = this.errorPasswordOrCheckGeneraPassword(false);
      if (errorPassword) {
        return true;
      }
    }

    return false;
  }

  errorPasswordOrCheckGeneraPassword(ignore_function_mode = true) {
    if (this.isEditExistentUser() && ignore_function_mode) return false;

    if (this.userDetailsForm.get('generaPassword')!.value) {
      return false;
    }
    if (this.userDetailsForm.get('password')!.value?.length > 0) {
      return false;
    }
    return true;
  }

  actualScore: ScoreRender = score0;
  suggestions: string[] = [];
  renderPasswordStrength() {
    const password = this.userDetailsForm.get('password')!.value;
    const passwordStrengthAnalized= passwordStrength(password);
    this.actualScore = passwordStrengthAnalized.score;
    this.suggestions = passwordStrengthAnalized.suggestions;
  }

  //Only in edit mode
  passwordLenghtError() {
    if (this.isInsertNewUser()) return;
    if (this.userDetailsForm.get('generaPassword')!.value) return;

    const password = this.userDetailsForm.get('password')!.value;
    if (password == null || password == '') {
      this.userDetailsForm.controls['password'].setValidators([]);
      this.userDetailsForm.controls['passwordConfirm'].setValidators([]);
      this.userDetailsForm.controls['password'].markAsPristine();
    } else {
      this.userDetailsForm.controls['password'].setValidators([Validators.required]);
    }
    this.userDetailsForm.controls['password'].updateValueAndValidity({ emitEvent: true });
  }
  isFormValid() {
    const password = this.userDetailsForm.get('password')!.value;

    if (password.length > 0 && this.actualScore.score < minimumPasswordScore) {
      return false;
    }
    return this.userDetailsForm.valid;
  }
  showPassword = false;
  showPasswordConfirm = false;
}
