import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ScoreRender, getGetObjScoreFromScore, minimumPasswordScore, passwordStrength, score0 } from 'src/app/core/utils/password_security';
import { UserService } from '../user-admin.service';

@Component({
  selector: 'app-form-enter-new-password',
  templateUrl: './form-enter-new-password.component.html',
  styleUrls: ['./form-enter-new-password.component.scss']
})
export class FormEnterNewPasswordComponent {

  token!:string
  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private userService: UserService
  ) {
    this.token = this.activeRoute.snapshot.params['token']!;
   }
  error?:string;
  isFormValid(){
    if(this.recoveryPasswordForm.invalid) return false;
    if(this.recoveryPasswordForm.value.password !== this.recoveryPasswordForm.value.confermaPassword) return false;
    if(this.actualScore.score < minimumPasswordScore) return false;
    return true
  }
  actualScore: ScoreRender = score0;
  suggestions: string[] = [];
  renderPasswordStrength() {
    const password = this.recoveryPasswordForm.get('password')!.value;
    const passwordStrengthAnalized= passwordStrength(password);
    this.actualScore = passwordStrengthAnalized.score;
    this.suggestions = passwordStrengthAnalized.suggestions;
  }

  success = false;
  onSubmitRecoveryPassword(){
    this.userService.changePassword(this.recoveryPasswordForm.value.password!,this.token).subscribe({
      next: data => {
        this.error = undefined;
        this.success = true;
      },
      error: error => {
        this.error = error.error.message;
      },
    });
  }
  showConfermaPassword = false;
  showPassword = false;
  recoveryPasswordForm = this.fb.group({
    password: ['', Validators.compose([Validators.required, Validators.minLength(8)])],

    confermaPassword: ['', Validators.compose([Validators.required, Validators.minLength(8)])],

  });
}
