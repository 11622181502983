import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../user-admin.service';
import { User } from 'src/app/core/models/user.model';
import { PageEvent } from '@angular/material/paginator';
import { TipologicaBase, TipologicaCamereDiCommercio } from 'src/app/core/models/tipologiche.model';
import { CPagination } from 'src/app/core/models/pagination.model';
import { RUOLO_ADMIN, RUOLO_CONSULENTE, RUOLO_OPERATORE } from 'src/app/core/costanti_ruoli';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
  constructor(
    private userAdminService: UserService,
    private router: Router
  ) {
    this.user_changed_email = this.router.getCurrentNavigation()?.extras.state?.['email_user'];
  }

  filterTextUser = '';
  filterRoleId = -1;
  filterCameraDiCommercioId = -1;

  listaRuoli: TipologicaBase[] = [
    { id: -1, nome: 'Tutti' },
    { id: 1, nome: RUOLO_ADMIN },
    { id: 2, nome: RUOLO_OPERATORE },
    { id: 3, nome: RUOLO_CONSULENTE },
  ];
  listaCamereDiCommercio: TipologicaCamereDiCommercio[] = [];
  listaCamereDiCommercioSublist: TipologicaCamereDiCommercio[] = [];

  pagination?: CPagination;
  user_changed_email?: string;
  users: User[] = [];
  displayedColumns = ['email', 'nome', 'cognome', 'ruolo', 'cameraDiCommercio', 'stato', 'actions'];
  pagination_current_page = 1;

  onPaginateChange(event: PageEvent) {
    this.pagination_current_page = event.pageIndex + 1;
    this.loadUsers();
  }
  searchCameraDiCommercio(event: string) {
    this.listaCamereDiCommercioSublist = this.listaCamereDiCommercio.filter(
      el => el.nome.toLowerCase().indexOf(event.toLowerCase()) > -1
    );
  }
  loadCamereDiCommercio() {
    this.userAdminService.getCamereDiCommercio().subscribe(res => {
      const el = { id: -1, nome: 'Tutte', attivo: false, accreditata: false};
      this.listaCamereDiCommercio = [el, ...res];
      this.listaCamereDiCommercioSublist = [el, ...res];
    });
  }
  loadUsers() {
    this.userAdminService
      .getUsers(
        this.pagination_current_page,
        this.filterTextUser,
        this.filterRoleId,
        this.filterCameraDiCommercioId
      )
      .subscribe(res => {
        this.users = res.items;
        this.pagination = {
          currentPage: res.currentPage,
          totalPage: res.totalElement,
          elementPerPage: res.elementPerPage,
          totalElement: res.totalElement,
        };
      });
  }
  ngOnInit(): void {
    this.loadCamereDiCommercio();
    this.loadUsers();
  }
}
