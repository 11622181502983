import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { UserSessionService } from 'src/app/core/services/user-session/user-session.service';

interface SignInRespose {
  authentication?: {
    token: string;
    user: {
      id: number;
      email: string;
      name: string;
      surname: string;
      role: string;
    };
  };
  error?: {
    message: string;
  };
}

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent {
  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private userSessionService: UserSessionService,
    private router: Router
  ) {}
  showPassword = false;
  signinForm = this.fb.group({
    email: ['', Validators.compose([Validators.required])],
    password: ['', Validators.compose([Validators.required])],
  });

  error: null | string = null;
  onSubmitSignIn() {
    console.log(this.signinForm.value);
    if (this.signinForm.valid === false) return;
    const formData = {
      email: this.signinForm.value.email,
      password: this.signinForm.value.password,
    };
    this.auth.signin(formData).subscribe(
      (res: SignInRespose) => {
          if(res.authentication === undefined) return console.log(res.error?.message);
          const authentication =  res.authentication;
          const token = authentication.token;
          const userData = authentication.user;
          this.userSessionService.setAccessToken(token);
          this.userSessionService.setUserData(userData);
          this.router.navigate(['/']);
          window.scrollTo(0, 0);

      },
      (err: SignInRespose) => {
        console.log(err);
        this.error = err.error?.message ?? "Errore di connessione, riprovare";
      }
    );
  }
}
