
export interface ScoreRender {
  bgColor: string;
  textColor: string;
  text: string;
  width: string;
  score: number;
}

export const score0 = {
  score: 0,
  bgColor: 'bg-rosso-scaduto',
  textColor: 'text-rosso-scaduto',
  text: 'La password non è abbastanza forte',
  width: 'w-1/5',
};
export const score1 = {
  score: 1,
  bgColor: 'bg-orange-500',
  textColor: 'text-orange-500',
  text: 'Password debole',
  width: 'w-2/5',
};
export const score2 = {
  score: 2,
  bgColor: 'bg-yellow-500',
  textColor: 'text-yellow-500',
  text: 'Password media',
  width: 'w-3/5',
};
export const score3 = {
  score: 3,
  bgColor: 'bg-green-400',
  textColor: 'text-green-400',
  text: 'Password forte',
  width: 'w-4/5',
};
export const score4 = {
  score: 4,
  bgColor: 'bg-green-500',
  textColor: 'text-green-500',
  text: 'Password fortissima',
  width: 'w-full',
};

export interface PasswordStrengthAnalysis {
  score: ScoreRender;
  suggestions: string[];
}

export function passwordStrength(password:string | null):PasswordStrengthAnalysis {
    
    if(password == null) password = '';
    
    const thereAreNumbers = /[0-9]/.test(password);
    const thereAreUppercase = /[A-Z]/.test(password);
    const thereAreLowercase = /[a-z]/.test(password);
    const thereAreSymbols = /[^0-9a-zA-Z]/.test(password);


    let score = 4;
    if(password.length > 25) {
      score = 4;
    }

    const suggestions = [];
    if(password.length < 8){
      suggestions.push('Inserisci almeno 8 caratteri');
      score = 0;
    }
    if(!thereAreNumbers){
      suggestions.push('Inserisci un numero');
      score = 0;
    }
    if(!thereAreUppercase){
      suggestions.push('Inserisci una lettera maiuscola');
      score--;
    }
    if(!thereAreLowercase){
      suggestions.push('Inserisci una lettera minuscola');
      score--;
    }
    if(!thereAreSymbols){
      suggestions.push('Inserisci un carattere speciale: !@#$%^&*()_+{}[]:<>?/.,');
      score--;
    }

    if(score < 0) score = 0;

    
    

    return {
      score: getGetObjScoreFromScore(score),
      suggestions
    }
}

export function getGetObjScoreFromScore(score: number): ScoreRender {
  switch (score) {
    case 0:
      return score0;
    case 1:
      return score1;
    case 2:
      return score2;
    case 3:
      return score3;
    case 4:
      return score4;
    default:
      return score0;
  }
}
export const minimumPasswordScore = 1;