<div
  class="border-solid border-b-innexta-cyan border-b-4 relative md:flex justify-center items-center hidden">
  <img src="../../../assets/img/home/banner-home.webp" alt="Banner home" class="w-full" />
  <div
    class="absolute home-container w-full m-auto h-5/6 rounded-3xl flex items-center justify-start">
    <div
      class="bg-innexta-gradient-transparent bg-opacity-30 w-full h-full absolute rounded-3xl mix-blend-multiply overflow-hidden">
      <img
        src="../../../assets/svg/logo-union-greca.svg"
        class="w-1/2 -translate-x-20 -translate-y-45 rotate-12" />
    </div>
    <div
      class="bg-innexta-gradient w-1/2 px-20 py-10"
      style="clip-path: polygon(0 1%, 100% 0, 80% 100%, 0% 100%)">
      <h3 class="font-semibold text-white text-3xl">PORTALE AGEVOLAZIONI</h3>
      <h3 class="font-semibold text-white text-2xl"></h3>
      <p class="font-semibold text-white text-base">
        La piattaforma delle Camere di commercio <br/> per il supporto alle imprese sulla finanza agevolata
      </p>
    </div>
  </div>
</div>

<h2 class="text-5xl font-bold text-center my-8 text-innexta-cyan">Le aree di servizio del Portale</h2>
<div class="m-auto mb-20 home-container">
  <div class="grid grid-cols-1 sm:grid-cols-2 gap-10">
    <ng-container
      [ngTemplateOutlet]="boxImmagine"
      [ngTemplateOutletContext]="{
        immagine: '../../../assets/img/home/formazione.jpg',
        titolo: 'Formazione',
        testotasto:'Scopri i webinar, gli eventi e le opportunità di formazione per un aggiornamento continuo'
      }">
    </ng-container>

    <ng-container
      [ngTemplateOutlet]="boxImmagine"
      [ngTemplateOutletContext]="{
        immagine: '../../../assets/img/home/bandi.jpg',
        titolo: 'InfoBandi',
        link: getInfoBandiLink(),
        dark: true,
        testotasto:'Controlla le richieste e naviga tra le misure agevolative per informare gli imprenditori'
      }">
    </ng-container>

    <ng-container
      [ngTemplateOutlet]="boxImmagine"
      [ngTemplateOutletContext]="{
        immagine: '../../../assets/img/home/news.jpg',
        titolo: 'News e documenti',
        testotasto:'Notizie, informazioni e aggiornamenti sul mondo della finanza agevolata',
        dark: true
      }">
    </ng-container>

    <ng-container
      [ngTemplateOutlet]="boxImmagine"
      [ngTemplateOutletContext]="{
        immagine: '../../../assets/img/home/fintech.jpg',
        titolo: 'Finanza complementare',
        testotasto:'Informazioni e servizi alle imprese per la finanza innovativa'
      }">
    </ng-container>
  </div>
</div>

<ng-template
  #boxImmagine
  let-immagine="immagine"
  let-titolo="titolo"
  let-titolo2="titolo2"
  let-link="link"
  let-dark="dark"
  let-testotasto="testotasto">
  <div class="w-full flex flex-col sm:flex-row"
  >
    <div
      class="flex-1 h-auto flex flex-col justify-evenly bg-innexta-gradient">
      <p class="ml-8 text-white font-semibold text-2xl">{{ titolo }} <br />{{ titolo2 }}</p>
      <div class="ml-8 flex items-center gap-5">
        <mat-icon
          [routerLink]="link"
          class="!min-w-[40px] !h-10 !text-4xl bg-white rounded-full hover:text-white transition-colors duration-300 text-center text-innexta-cyan hover:bg-innexta-cyan"
          (click)="link && scrollToTop()"
          >navigate_next</mat-icon
        >
        <p class="text-white font-semibold text-sm pr-3">{{testotasto}}</p>
      </div>
    </div>
    <div class="flex-1 h-1/2 sm:h-full overflow-hidden">
      <img class="w-full h-full" [src]="immagine" [alt]="titolo" />
    </div>
  </div>
</ng-template>
