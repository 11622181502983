import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserListComponent } from './user-list/user-list.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { FormRecoveryPasswordComponent } from './form-recovery-password/form-recovery-password.component';
import { FormEnterNewPasswordComponent } from './form-enter-new-password/form-enter-new-password.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';



@NgModule({
  declarations: [UserListComponent, UserDetailsComponent, FormRecoveryPasswordComponent, FormEnterNewPasswordComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    NgxMatSelectSearchModule

  ],
  exports: [
    UserListComponent,
    UserDetailsComponent
  ]
})
export class UserManagementModule { }
