import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../user-admin.service';

@Component({
  selector: 'app-form-recovery-password',
  templateUrl: './form-recovery-password.component.html',
  styleUrls: ['./form-recovery-password.component.scss'],
})
export class FormRecoveryPasswordComponent {
  constructor(
    private fb: FormBuilder,
    private userService: UserService
  ) {}
  recoveryPasswordForm = this.fb.group({
    email: ['', Validators.compose([Validators.required, Validators.email])],
    confermaEmail: ['', Validators.compose([Validators.required, Validators.email])],
  });
  error?: string;
  confirmMessage?: string;

  checkConfirmEmail() {
    if (this.recoveryPasswordForm.value.email === this.recoveryPasswordForm.value.confermaEmail) {
      return true;
    }
    return false;
  }
  
  onSubmitRecoveryPassword() {
    this.userService.requestResetPassword(this.recoveryPasswordForm.value.email!).subscribe({
      next: data => {
        this.confirmMessage = data.message;
        this.error = undefined;
      },
      error: error => {
        this.error = error.error.message;
        this.confirmMessage = undefined;
      },
    });
  }

  isFormValid() {
    if (this.recoveryPasswordForm.invalid) {
      return false;
    }
    return this.checkConfirmEmail();
  }
  submitted = false;
}
