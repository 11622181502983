import {Component, OnInit} from "@angular/core";
import {StatisticheService} from "./statistiche.service";
import {Filter, FilterCCIAA, FilterValue} from "../../core/models/filter.model";
import {TipologicheService} from "../../core/services/tipologiche/tipologiche.service";
import {UserSessionService} from "../../core/services/user-session/user-session.service";
import {GestioneRichiesteService} from "../gestione-richieste/gestione-richieste.service";

type Tab = 'richieste' | 'richieste_complessive' ;

const tipologiaFiltroLambda = (el: TipologicaFiltro) => {
  return {
    id: el.id ? `${el.id}` : el.nome,
    name: el.nome,
    selected: false
  };
};

type TipologicaFiltro = {
  id: string;
  nome: string;
};

@Component({
  selector: 'app-statistiche',
  templateUrl: './statistiche.component.html',
  styleUrls: ['./statistiche.component.scss'],
})
export class StatisticheComponent implements OnInit {
  constructor(private statisticheService: StatisticheService,
              private tipologicheService: TipologicheService,
              public userSessionService: UserSessionService,
              private gestioneRichesteServise: GestioneRichiesteService) {
  }

  private echartsInstance: any;
  richiesteCamera: any;
  richiesteFinalita: any;
  richiesteDimensioneInvestimento: any;
  richiesteFatturato: any;
  richiesteMeseAnno: any;
  richiesteSettore: any;
  richiesteStatoLavorazione: any;
  richiesteTipoSoggetto: any;

  selectedTab: Tab = 'richieste';

  filters: FilterCCIAA[] = [
    { id: 'camere', name: 'CCIAA', values: [], actived_value: new Map() }
  ];

  idsCCIAA: string = "";

  admin: boolean = this.userSessionService.isAdmin();

  onChartInit(ec: any){
    this.echartsInstance = ec;
  }

  ngOnInit() {
    console.log("StatisticheComponent OnInit");
    if (!this.userSessionService.isAdmin())
      this.idsCCIAA = this.userSessionService.getUserData()?.cameraDiCommercio?.id;
    this.tipologicheService.getTipologicaCamere().subscribe( data => {
      for (let i = 0; i < data.length; i++) {
        this.filters[0].values.push({id:data[i].id,name:data[i].nome,selected:false});
      }
    });

    this.loadRichiesteCamera();
    this.loadRichiesteFinalita();
    this.loadRichiesteDimensioneInvestimento();
    this.loadRichiesteFatturato();
    this.loadRichiesteMeseAnno();
    this.loadRichiesteSettore();
    this.loadRichiesteStatoLavorazione();
    this.loadRichiesteTipoSoggetto();
  }

  loadRichiesteCamera(){
    this.statisticheService.getRichiesteCamera().subscribe( data => {
      let valori = [];
      valori = this.costruisciJsonTorta(data);
      this.richiesteCamera = {
        title: {
          text: 'Richieste per CCIAA',
          /*subtext: 'Fake Data',*/
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'horizontal',
          bottom: 'bottom'
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        series: [
          {
            name: 'Richieste',
            type: 'pie',
            radius: '70%',
            data: valori,
            label: {show: true, position: "inside", formatter: '{c}'},
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
    });
  }

  loadRichiesteFinalita(){
    this.statisticheService.getRichiesteFinalita(this.idsCCIAA).subscribe( data => {

      let valori = [];
      valori = this.costruisciJsonTorta(data);

      this.richiesteFinalita = {
        title: {
          text: 'Richieste per finalità',
          subtext: 'Il numero di richieste potrebbero essere superiori a quelle totali dal momento che si possono indicare fino a tre finalità per richiesta',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'horizontal',
          bottom: 'bottom'
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        series: [
          {
            name: 'Richieste',
            type: 'pie',
            radius: '80%',
            data: valori,
            label: {show: true, position: "inside", formatter: '{c}'},
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
    });
  }

  loadRichiesteDimensioneInvestimento(){
    this.statisticheService.getRichiesteDimensioneInvestimento(this.idsCCIAA).subscribe( data => {

      this.richiesteDimensioneInvestimento = {
        title: {
          text: 'Richieste per dimensione investimento',
          /*subtext: 'Fake Data',*/
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          data: data.elements.descrizioni,
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            rotate: 30
          },
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'Richieste',
            data: data.elements.richieste,
            type: 'bar',
            label: {show: true, position: "inside", formatter: '{c}'},
            barWidth: '60%'
          }
        ]
      };
    });
  }

  loadRichiesteFatturato(){
    this.statisticheService.getRichiesteFatturato(this.idsCCIAA).subscribe( data => {

      this.richiesteFatturato = {
        title: {
          text: 'Richieste per fatturato',
          /*subtext: 'Fake Data',*/
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          data: data.elements.descrizioni,
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            rotate: 30
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'Richieste',
            data: data.elements.richieste,
            label: {show: true, position: "inside", formatter: '{c}'},
            type: 'bar',
            barWidth: '60%'
          }
        ]
      };
    });
  }

  loadRichiesteMeseAnno(){
    this.statisticheService.getRichiesteMeseAnno().subscribe( data => {
      this.richiesteMeseAnno = {
        title: {
          text: 'Richieste per mese/anno',
          /*subtext: 'Fake Data',*/
          left: 'center'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          data: data.elements.descrizioni,
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            rotate: 30
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'Richieste',
            data: data.elements.richieste,
            type: 'bar',
            label: {show: true, position: "inside", formatter: '{c}'},
            barWidth: '60%'
          }
        ]
      };
    });
  }

  loadRichiesteSettore(){
    this.statisticheService.getRichiesteSettore(this.idsCCIAA).subscribe( data => {
      let valori = [];
      valori = this.costruisciJsonTorta(data);

      this.richiesteSettore = {
        title: {
          text: 'Richieste per settore',
          subtext: 'Il numero di richieste potrebbero essere superiori a quelle totali dal momento che si possono indicare fino a tre settori per richiesta',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'horizontal',
          bottom: 'bottom'
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        series: [
          {
            name: 'Richieste',
            type: 'pie',
            radius: '80%',
            data: valori,
            label: {show: true, position: "inside", formatter: '{c}'},
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
    });
  }

  loadRichiesteStatoLavorazione(){
    this.statisticheService.getRichiesteStatoLavorazione(this.idsCCIAA).subscribe( data => {
      let valori = [];
      valori = this.costruisciJsonTorta(data);

      this.richiesteStatoLavorazione = {
        title: {
          text: 'Richieste per stato di lavorazione',
          /*subtext: 'Fake Data',*/
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'horizontal',
          bottom: 'bottom'
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        series: [
          {
            name: 'Richieste',
            type: 'pie',
            radius: '80%',
            data: valori,
            label: {show: true, position: "inside", formatter: '{c}'},
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
    });
  }

  loadRichiesteTipoSoggetto(){
    this.statisticheService.getRichiesteTipoSoggetto(this.idsCCIAA).subscribe( data => {
      let valori = [];
      valori = this.costruisciJsonTorta(data);

      this.richiesteTipoSoggetto = {
        title: {
          text: 'Richieste per tipologia di soggetto',
          /*subtext: 'Fake Data',*/
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'horizontal',
          bottom: 'bottom'
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        series: [
          {
            name: 'Richieste',
            type: 'pie',
            radius: '80%',
            data: valori,
            label: {show: true, position: "inside", formatter: '{c}'},
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
    });
  }

  costruisciJsonTorta(data: any){
    let valori = [];
    let valore: Valore;
    for (let i = 0; i < data.elements?.descrizioni?.length; i++) {
      valore = new Valore();
      valore.name = data.elements.descrizioni[i].value;
      valore.value = data.elements.richieste[i].value;
      valori.push(valore);
    }
    return valori;
  }

  clickButtonResetSpecificFilter(filter: FilterCCIAA) {
    filter.values.forEach(value => {
      value.selected = false;
    });
    filter.actived_value = new Map();
    this.idsCCIAA = "";
    this.loadRichiesteIdsCCIAA();
  }
  clickButtonResetAllFilters() {
    this.filters.forEach(filter => this.clickButtonResetSpecificFilter(filter));
    this.idsCCIAA = "";
    this.loadRichiesteIdsCCIAA();
  }

  changeFilterAttributeStatus(filter: FilterCCIAA, filter_value: FilterValue) {
    filter_value.selected = !filter_value.selected;
    (document.activeElement! as HTMLElement).blur();
    if (filter.actived_value.get(filter_value.id)) {
      filter.actived_value.delete(filter_value.id);
    } else {
      filter.actived_value.set(filter_value.id, filter_value);
    }
    let idsCCIAA = [];
    for (const [key, value] of filter.actived_value.entries()) {
      idsCCIAA.push(key);
    }
    this.idsCCIAA = idsCCIAA.toString();
    this.loadRichiesteIdsCCIAA();
  }

  loadRichiesteIdsCCIAA(){
    this.loadRichiesteTipoSoggetto();
    this.loadRichiesteFinalita();
    this.loadRichiesteSettore();
    this.loadRichiesteFatturato();
    this.loadRichiesteDimensioneInvestimento();
    this.loadRichiesteStatoLavorazione();
  }

  getFilterById(id: string) {
    return this.filters.find(filter => filter.id == id);
  }

  clickDownloadXlsx() {
    let cameraDiCommercio = "";
    if (!this.userSessionService.isAdmin())
      cameraDiCommercio = this.userSessionService.getUserData()?.cameraDiCommercio?.id;
    else
      cameraDiCommercio = this.idsCCIAA.toString();
    this.gestioneRichesteServise
      .downloadXlsxRichieste(
        "",
        -1,
        'asc',
        'data_creazione',
        '',
        cameraDiCommercio,
        false
      )
      .subscribe((data: any) => {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'lista-richieste.xlsx';
        link.click();
      });
  }
}

class Valore {
  name: string | undefined
  value: number | undefined
}
