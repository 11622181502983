import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';

@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
  itemsPerPageLabel = 'di';
  lastPageLabel = 'Ultima pagina';
  nextPageLabel = 'Pagina successiva';
  previousPageLabel = 'Pagina precedente';
  firstPageLabel = 'Prima pagina';

  changes = new Subject<void>();
  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return `0 di ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} di ${length}`;
  }
}
