import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_ENDPOINT } from 'src/app/app.settings';
import { User } from '../../models/user.model';



@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  public signup(data: any): any {
    return this.http.post(`${API_ENDPOINT}/api/registrati`, data);
  }


  public signin(data: any): any {
    return this.http.post(`${API_ENDPOINT}/api/accedi`, data, { headers: { 'X-Skip-Interceptor': 'true' } });
  }
  public userData() {
    return this.http.get<{
      user: User
    }>(`${API_ENDPOINT}/api/io`);
  }
}
