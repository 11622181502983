import { Component, OnInit, ViewChild } from '@angular/core';
import { Bando } from 'src/app/core/models/bando';
import { BandiService } from '../bandi.service';
import { Filter, FilterValue } from '../../../core/models/filter.model';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { KEY_BANDI_FILTER } from 'src/app/core/costanti_sessione';
import { CPagination } from 'src/app/core/models/pagination.model';

const tipologiaFiltroLambda = (el: TipologicaFiltro) => {
  return {
    id: el.id ? `${el.id}` : el.nome,
    name: el.nome,
    selected: false,
    conteggio: el.conteggio,
  };
};

type TipologicaFiltro = {
  id: string;
  nome: string;
  conteggio: number;
};

@Component({
  selector: 'app-bandi',
  templateUrl: './bandi.component.html',
  styleUrls: ['./bandi.component.scss'],
})
export class BandiComponent implements OnInit {
  constructor(private bandiService: BandiService) { }

  bandi_pagination?: CPagination;
  bandi: Bando[] = [];
  bandi_datasource = new MatTableDataSource<Bando>(this.bandi);
  pagination_current_page = 1;
  @ViewChild(MatPaginator) bandi_paginator!: MatPaginator;
  onPaginateChange(event: PageEvent) {
    this.pagination_current_page = event.pageIndex + 1;
    this.load_bandi();
  }
  ngOnInit() {
    this.loadFilterFromSession();
    this.load_bandi(true);
  }
  load_bandi(first_time = false) {
    this.bandiService
      .getBandiSearch(this.filter_title, this.getActiveFilters(), this.pagination_current_page)
      .subscribe((data: any) => {
        this.bandi = data.bandi.map((el: Bando) => {
          el.selected = false;
          return el;
        });

        const resp_filtri = data.filtri;
        const listaChiavi = Object.keys(resp_filtri);
        listaChiavi.forEach(key => {
          const filterSearched = this.getFilterById(key);
          if (filterSearched) filterSearched.values = resp_filtri[key].map(tipologiaFiltroLambda);
          else console.warn('Filtro non trovato: ' + key);
        });

        this.bandi_pagination = {
          currentPage: data.currentPage,
          elementPerPage: data.elementPerPage,
          totalElement: data.totalElement,
          totalPage: data.totalPage,
        };
        if (!first_time) {
          this.saveFilterToSession();
        }
      });
  }
  saveFilterToSession() {
    const sessionFilterToSave = {
      search_title: this.filter_title,
      filters: this.getActiveFilters(),
      pagination_current_page: this.pagination_current_page,
    };
    localStorage.setItem(KEY_BANDI_FILTER, JSON.stringify(sessionFilterToSave));
  }

  loadFilterFromSession() {
    const sessionFilter = localStorage.getItem(KEY_BANDI_FILTER);
    if (!sessionFilter) return;
    const sessionFilterParsed = JSON.parse(sessionFilter);
    this.filter_title = sessionFilterParsed.search_title;
    //Reset per evitare bug
    this.filters.forEach(filter => {
      filter.values.forEach(value => (value.selected = false));
    });

    sessionFilterParsed.filters.forEach((filter: Filter) => {
      filter.values.forEach(value => {
        const filter_to_update = this.filters.find(f => f.id == filter.id);
        if (!filter_to_update) return;
        filter_to_update.actived_value.set(value.id, value);
      });
    });

    this.pagination_current_page = sessionFilterParsed.pagination_current_page;

  }

  getActiveFilters() {
    const filters: Filter[] = this.filters.map(filter => {
      return {
        id: filter.id,
        name: filter.name,
        values: [...filter.actived_value.values()],
        actived_value: filter.actived_value,
      };
    });
    return filters;
  }
  filters: Filter[] = [
    { id: 'settori', name: 'Settore', values: [], actived_value: new Map() },
    { id: 'strategie', name: 'Strategie', values: [], actived_value: new Map() },
    { id: 'organizzazioni', name: 'Organizzazione', values: [], actived_value: new Map() },
    { id: 'ambiti', name: 'Ambito', values: [], actived_value: new Map() },
    { id: 'nazioni', name: 'Nazione', values: [], actived_value: new Map() },
    { id: 'regioni', name: 'Regione', values: [], actived_value: new Map() },
    { id: 'province', name: 'Provincia', values: [], actived_value: new Map() },
    { id: 'stato', name: 'Stato agevolazioni', values: [], actived_value: new Map() },
    { id: 'tipologiaAiuto', name: 'Tipologia aiuto', values: [], actived_value: new Map() },
    { id: 'tags', name: 'Tags', values: [], actived_value: new Map() },
  ];
  getFilterById(id: string) {
    return this.filters.find(filter => filter.id == id);
  }

  filter_title = '';
  clickButtonResetSpecificFilter(filter: Filter, load_bandi = true) {
    filter.values.forEach(value => {
      value.selected = false;
    });
    filter.actived_value = new Map();
    this.pagination_current_page = 1;
    if (load_bandi) {
      this.load_bandi();
    }
  }
  clickButtonResetAllFilters(load_bandi = true) {
    this.filters.forEach(filter => this.clickButtonResetSpecificFilter(filter, false));
    this.pagination_current_page = 1;
    this.filter_title = '';
    if (load_bandi) {
      this.load_bandi();
    }
  }

  changeFilterAttributeStatus(filter: Filter, filter_value: FilterValue) {
    filter_value.selected = !filter_value.selected;
    (document.activeElement! as HTMLElement).blur();
    if (filter.actived_value.get(filter_value.id)) {
      filter.actived_value.delete(filter_value.id);
    } else {
      filter.actived_value.set(filter_value.id, filter_value);
    }
    this.pagination_current_page = 1;
    this.load_bandi();
  }
}
