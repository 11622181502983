import { Component } from '@angular/core';
import { listTipologiche } from '../costanti_tipologiche';

@Component({
  selector: 'app-tipologiche-list-all',
  templateUrl: './tipologiche-list-all.component.html',
  styleUrls: ['./tipologiche-list-all.component.scss'],
})
export class TipologicheListAllComponent {
  displayedColumns: string[] = ['name', 'actions'];
  dataSource = listTipologiche;
}
