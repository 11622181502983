import { Component } from '@angular/core';
import { UserSessionService } from 'src/app/core/services/user-session/user-session.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  constructor(private userSessionService: UserSessionService) { }
    
  public getInfoBandiLink(): string {
    if (this.userSessionService.isConsulente()) {
      return '/bandi';
    }
    if (this.userSessionService.isOperatoreCamera() || this.userSessionService.isAdmin()) {
      return '/gestione-richieste';
    }
    return '/bandi';
  }


  scrollToTop(){
    window.scrollTo(0, 0);
  }
}
