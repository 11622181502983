<div class="custom-container flex flex-col rounded-3xl my-8 shadow-custom-1">
  <div
    class="rounded-t-3xl bg-innexta-gradient p-8 flex flex-wrap justify-center items-center gap-5">
    <h1 class="text-white text-3xl font-bold">Bandi</h1>
    <p class="font-light text-sm text-white">Affina la ricerca dei bandi selezionando i filtri</p>
    <div class="input-search-style">
      <input
        name="search_title"
        [(ngModel)]="filter_title"
        placeholder="Ricerca per nome"
        (keyup.enter)="load_bandi()" />
      <button class="search-button" (click)="load_bandi()">cerca</button>
    </div>
  </div>
  <div class="rounded-b-3xl bg-white p-8">
    <div #filtro class="flex flex-row gap-5 flex-wrap filtri-bandi" id="filtro_bandi">
      <app-filtro-ricerca-bandi-singolo
        *ngFor="let filter of filters"
        [filter]="filter"
        [changeFilterAttributeStatus]="changeFilterAttributeStatus.bind(this)"
        [values]="filter.values"
        [clickButtonResetSpecificFilter]="clickButtonResetSpecificFilter.bind(this)" />
      <button class="reset-button-all" (click)="clickButtonResetAllFilters()">
        Pulisci filtri 
      </button>
    </div>
  </div>
</div>

<div class="flex flex-col gap-5 container m-auto px-4 sm:px-0 relative mb-5">
  <ng-template [ngTemplateOutlet]="paginazioneTabella" />

  <table mat-table [dataSource]="bandi" class="mat-elevation-z8 tabella-bandi">
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef></th>
      <td
        mat-cell
        *matCellDef="let bando"
        [routerLink]="'/bandi/' + bando.id"
        class="grid-area-icon">
        <app-icona-stato-bandi [status]="bando.stato" />
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>Titolo</th>
      <td
        mat-cell
        *matCellDef="let bando"
        [routerLink]="'/bandi/' + bando.id"
        class="title grid-area-title font-light">
        {{ bando.titolo }}
      </td>
    </ng-container>

    <ng-container matColumnDef="openDate">
      <th mat-header-cell *matHeaderCellDef class="whitespace-nowrap">Data apertura</th>
      <td
        mat-cell
        *matCellDef="let bando"
        [routerLink]="'/bandi/' + bando.id"
        class="whitespace-nowrap grid-area-data_apertura font-light">
        <p *ngIf="bando.dataApertura" class="sm:hidden">Data Apertura:</p>
        <ng-container *ngIf="bando.dataApertura">
          {{ bando.dataApertura | date: 'dd/MM/YYYY' }}
        </ng-container>
        <ng-container *ngIf="!bando.dataApertura"> - </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="closeDate">
      <th mat-header-cell *matHeaderCellDef class="whitespace-nowrap">Data chiusura</th>
      <td
        mat-cell
        *matCellDef="let bando"
        [routerLink]="'/bandi/' + bando.id"
        class="whitespace-nowrap grid-area-data_chiusura font-light">
        <p *ngIf="bando.dataChiusura" class="sm:hidden">Data Chiusura:</p>
        <ng-container *ngIf="bando.dataChiusura">
          {{ bando.dataChiusura | date: 'dd/MM/YYYY' }}
        </ng-container>
        <ng-container *ngIf="!bando.dataChiusura"> - </ng-container>
      </td>
    </ng-container>
    <tr
      class="table-head"
      mat-header-row
      *matHeaderRowDef="['status', 'title', 'openDate', 'closeDate']"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['status', 'title', 'openDate', 'closeDate']"
      class="leading-loose hover:shadow-lg table-row riga-tabella no-underline hover:cursor-pointer"></tr>
  </table>

  <ng-template [ngTemplateOutlet]="paginazioneTabella" />
</div>

<ng-template #paginazioneTabella>
  <div class="flex justify-between md:flex-row flex-col gap-5">
    <div class="legenda flex gap-5 flex-wrap flex-row items-center">
      <div class="font-light inline">Bandi:</div>
      <div class="font-bold text-black inline-flex items-center gap-3">
        Attivi
        <mat-icon class="bg-union-blue-dark rounded-full text-white text-base text-center"
          >check</mat-icon
        >
      </div>
      <div class="font-bold text-black inline-flex items-center gap-3">
        Scaduti
        <mat-icon class="bg-rosso-scaduto rounded-full text-white text-base text-center"
          >close</mat-icon
        >
      </div>
      <div class="font-bold text-black inline-flex items-center gap-3">
        Senza data di chiusura
        <mat-icon class="bg-innexta-cyan rounded-full text-white text-base text-center"
          >all_inclusive</mat-icon
        >
      </div>
    </div>

    <mat-paginator
      *ngIf="bandi_pagination"
      [hidePageSize]="true"
      [pageSize]="bandi_pagination.elementPerPage"
      [pageIndex]="bandi_pagination.currentPage - 1"
      [length]="bandi_pagination.totalElement"
      [showFirstLastButtons]="true"
      (page)="onPaginateChange($event)"></mat-paginator>
  </div>
</ng-template>
